<template>
  <div>
    <div class="spinner--container" v-if="isLoading">
      <CSpinner style="border-right-color: white" />
    </div>
    <CModal
      :show.sync="show"
      alignment="center"
      class="editRoleModal"
      size="xl"
      :draggable="false"
    >
      <template v-slot:header>
        <h3>{{ $t("Edit Roles") }}</h3>
        <CButton @click="close">
          <i class="fa fa-times"></i>
        </CButton>
      </template>

      <div class="modal-body">
        <div
          class="d-flex position-relative roleManagementContainer"
          style=""
          ref="detailsCard"
          v-if="gotRights"
        >
          <!-- Front Office card -->
          <div
            :style="[
              'display:flex ',
              isDetailsShow ? 'height:90%' : 'height:100%',
            ]"
          >
            <div class="front-office-card">
              <div>
                <h3 style="font-size: 18px">Front Office</h3>
                <div
                  @click="showRoles(role, $event)"
                  :class="{
                    highlighted: isDetailsShow && selectedRole === role,
                  }"
                  v-for="(role, index) in frontOfficeRoles"
                  :key="index"
                >
                  <div
                    style="padding: 6px 20px; cursor: pointer"
                    class="d-flex"
                  >
                    <div
                      style="margin-top: 1px"
                      class="d-flex justify-content-between w-100 px-1"
                    >
                      <li style="display: flex; gap: 8px; align-items: center">
                        <i class="fa fa-angle-right"></i
                        ><span>{{ $t(role.name) }} </span>
                      </li>
                    </div>
                  </div>
                </div>
              </div>

              <div style="margin-top: 20px">
                <h3 style="font-size: 18px">Back Office</h3>
                <div
                  @click="showRoles(role, $event)"
                  :class="{
                    highlighted: isDetailsShow && selectedRole === role,
                  }"
                  v-for="(role, index) in backOfficeRoles"
                  :key="index"
                >
                  <div
                    style="padding: 6px 20px; cursor: pointer"
                    class="d-flex"
                  >
                    <div
                      style="margin-top: 1px"
                      class="d-flex justify-content-between w-100 px-1"
                    >
                      <li style="display: flex; gap: 8px; align-items: center">
                        <i class="fa fa-angle-right"></i
                        ><span>{{ $t(role.name) }} </span>
                      </li>
                    </div>
                  </div>
                </div>

                <div class="mt-3" style="display: none">
                  <CInput :label="$t('Add new role')" v-model="newRole" />
                  <div style="display: flex; justify-content: end">
                    <CButton class="add-role" @click="createRole">
                      {{ $t("Create new role") }}
                      <i
                        class="fa fa-plus text-white"
                        style="font-size: 10px"
                      ></i>
                    </CButton>
                  </div>
                </div>
              </div>

              <div style="margin-top: 20px; display: flex; gap: 20px">
                <el-input
                  v-model="newRoleName"
                  style="width: 240px; height: 35px"
                  :placeholder="$t('New Role')"
                />
                <CButton
                  @click="addNewRole"
                  color="primary"
                  class="modal-confirm"
                  style="min-width: 100px"
                >
                  {{ $t("Add") }}
                </CButton>
              </div>
            </div>
            <transition name="fade" class="role-card-mobile">
              <div v-if="isDetailsShow" class="role-card">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    overflow: inherit;
                  "
                >
                  <div
                    class="align-items-center gap-2 close-rights-role"
                    @click="isDetailsShow = false"
                  >
                    <i
                      class="fa fa-chevron-left"
                      style="cursor: pointer; color: #0071ce"
                    ></i>
                    <span>{{ $t("Back") }}</span>
                  </div>
                  <h3 class="p-0 m-0" style="font-size: 18px">
                    {{ $t("Rights for") }} {{ $t(selectedRole.name) }}
                  </h3>
                  <div
                    style="
                      overflow: scroll;
                      display: flex;
                      flex-direction: column;
                      gap: 15px;
                    "
                  >
                    <div
                      v-for="(value, key) in getGroupedAccesses(selectedRole)"
                      :key="key"
                    >
                      <h5
                        class="p-0 m-0"
                        style="font-weight: 700; font-size: 15px"
                      >
                        {{ $t(value.name) }}
                      </h5>

                      <div
                        class="d-flex gap-3"
                        v-for="(right, index) in value.permissions"
                        :key="right"
                      >
                        <div
                          class="d-flex gap-3"
                          style="align-items: center"
                          v-if="permissions[right.name]"
                        >
                          <!-- if some "right" isnt showing even if its sent in the
                          request, then remove the v-if, it might be the reason,
                          or check how its written and compare it in
                          permissionsText.js -->

                          <!-- <input
                          :data-testid="'right-' + key + '-' + index"
                          :disabled="isDisabled(right, value)"
                          type="checkbox"
                          :id="'right-' + key + '-' + index"
                          v-model="right.checked"
                          @change="rightChanged(right, value)"
                        /> -->
                          <input
                            style="margin-top: -3px"
                            :data-testid="'right-' + key + '-' + index"
                            :disabled="isDisabled(right, value)"
                            type="checkbox"
                            :id="'right-' + key + '-' + index"
                            v-model="right.status"
                            @change="rightChanged(right, value)"
                          />
                          <label
                            style="margin-top: 2.4px"
                            :for="'right-' + key + '-' + index"
                          >
                            {{ permissions[right.name] }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="btns">
          <CButton @click="close" class="link">{{ $t("Cancel") }}</CButton>
          <CButton
            color="primary"
            class="modal-confirm"
            @click="saveRoles"
            v-if="isDetailsShow"
          >
            {{ $t("Save") }}
          </CButton>
        </div>
        <!-- @click="saveRoleSystem()" -->
      </template>
    </CModal>
  </div>
</template>

<script>
import { UsersService } from "@/services/users";
import { OrganizationsService } from "@/services/organizations";
import { ContentLoader } from "vue-content-loader";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { showMessage } from "../../../../utils/helpers";
import permissionsText from "@/constants/permissionsText";
import { mapState } from "vuex";

export default {
  components: {
    ContentLoader,
    OrganizationSelector,
  },

  data() {
    return {
      permissions: permissionsText,
      newRole: "",
      isLoading: false,
      isDetailsShow: false,
      gotRights: false,
      selectedRole: null,
      selectedRights: [],
      frontOfficeRoles: [],
      backOfficeRoles: [],
      newRoleName: "",
    };
  },
  props: {
    show: Boolean,
    projectData: Object,
  },
  computed: {
    ...mapState({
      currentOrganizationObj: (state) => state.orgObj,
    }),
    isMobile() {
      return window.innerWidth <= 808;
    },
  },
  mounted() {
    this.clickOutsideListener = (event) => {
      if (
        this.$refs.detailsCard &&
        !this.$refs.detailsCard.contains(event.target)
      ) {
        this.isDetailsShow = false;
      }
    };
    document.addEventListener("click", this.clickOutsideListener);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.clickOutsideListener);
  },
  watch: {
    show: function (val) {
      if (val) {
        this.projectRoles = this.projectData.role_system;

        this.frontOfficeRoles = this.projectData.roles.filter(
          (role) => role.scope === "organization"
        );
        this.backOfficeRoles = this.projectData.roles.filter(
          (role) => role.scope === "project"
        );

        this.gotRights = true;
      }
    },
  },

  methods: {
    getGroupedAccesses(role) {
      const groupedAccesses = [];
      const singlePermissionSections = [];

      role.accesses.forEach((access) => {
        // if (access.name === "Training") {
        //   return;
        // }
        if (access.permissions.length === 1) {
          singlePermissionSections.push(...access.permissions);
        } else {
          groupedAccesses.push(access);
        }
      });

      if (singlePermissionSections.length > 0) {
        groupedAccesses.push({
          name: "Other",
          permissions: singlePermissionSections,
        });
      }

      return groupedAccesses;
    },
    async addNewRole() {
      const { data } = await OrganizationsService.add_new_role({
        role_name: this.newRoleName.trim(),
        project_id: this.projectData._id,
      });
      if (data.success) {
        showMessage("success", "Role Added Successfully");
        this.frontOfficeRoles = data.new_roles.filter(
          (role) => role.scope === "organization"
        );
        this.backOfficeRoles = data.new_roles.filter(
          (role) => role.scope === "project"
        );
        this.$parent.list_organizations_and_projects();
        this.newRoleName = "";
        this.$parent.changeProjectRoleOptions(data.new_roles);
      } else {
        showMessage("error", data.message);
      }
    },
    isDisabled(currentRight, rightsGroup) {
      if (
        rightsGroup.permissions.length > 1 &&
        currentRight != rightsGroup.permissions[0]
      ) {
        if (rightsGroup.permissions[0].status === false) {
          return true;
        }
      }
      if (rightsGroup.name.toLowerCase() != "actions") {
        return false;
      }
      const viewPagePermission = rightsGroup.permissions.find(
        (permission) => permission.name === "viewActionsPage"
      ).status;
      if (!viewPagePermission) {
        if (!currentRight.name.toLowerCase().includes("page")) {
          rightsGroup.permissions.forEach((permission) => {
            permission.status = false;
          });
          return true;
        }
      }
      if (currentRight.name.toLowerCase().includes("page")) {
        return false;
      }
      if (
        !currentRight.name.toLowerCase().includes("add") &&
        !currentRight.name.toLowerCase().includes("modify") &&
        !currentRight.name.toLowerCase().includes("delete") &&
        !currentRight.name.toLowerCase().includes("remove") &&
        !currentRight.name.toLowerCase().includes("view")
      ) {
        return false;
      }

      const index = rightsGroup.permissions.findIndex(
        (permission) => permission.name === currentRight.name
      );
      if (currentRight.name.toLowerCase().includes("all")) {
        if (index - 1 < rightsGroup.permissions.length) {
          return !rightsGroup.permissions[index - 1].status;
        }
      } else if (currentRight.name.toLowerCase().includes("team")) {
        if (index - 1 < rightsGroup.permissions.length) {
          return !rightsGroup.permissions[index - 1].status;
        }
      } else {
        return false;
      }
    },

    rightChanged(selectedRight, rightsGroup) {
      if (
        selectedRight == rightsGroup.permissions[0] &&
        selectedRight.status == false
      ) {
        rightsGroup.permissions.forEach((permission) => {
          permission.status = false;
        });
        return;
      }
      if (rightsGroup.name.toLowerCase() != "actions") {
        return;
      }
      if (selectedRight.name.toLowerCase().includes("page")) {
        return;
      }
      if (
        !selectedRight.name.toLowerCase().includes("add") &&
        !selectedRight.name.toLowerCase().includes("modify") &&
        !selectedRight.name.toLowerCase().includes("delete") &&
        !selectedRight.name.toLowerCase().includes("remove") &&
        !selectedRight.name.toLowerCase().includes("view")
      ) {
        return;
      }

      const index = rightsGroup.permissions.findIndex(
        (permission) => permission.name === selectedRight.name
      );
      if (selectedRight.name.toLowerCase().includes("all")) {
        if (selectedRight.status) {
          if (index - 1 < rightsGroup.permissions.length) {
            rightsGroup.permissions[index - 1].status = true;
          }
          if (index - 2 < rightsGroup.permissions.length) {
            rightsGroup.permissions[index - 2].status = true;
          }
        }
      } else if (selectedRight.name.toLowerCase().includes("team")) {
        if (selectedRight.status) {
          if (index - 1 < rightsGroup.permissions.length) {
            rightsGroup.permissions[index - 1].status = true;
          }
        }
      } else {
      }
    },
    async saveRoles() {
      const payload = {
        organization_id: this.currentOrganizationObj._id,
        role_data: [...this.frontOfficeRoles, ...this.backOfficeRoles],
      };
      const { message, success } = await OrganizationsService.editRoleSystem(
        payload
      );
      if (success) {
        showMessage("success", message);

        this.close();
      } else {
        showMessage("error", message);
      }
      this.isLoading = false;
      if (this.isMobile) {
        this.isDetailsShow = false;
      }
    },
    showRoles(role, event) {
      event.stopPropagation(); // Add this line
      this.selectedRole = role;
      let roleRights =
        this.frontOfficeRoles.find((r) => r.name === role.name) ||
        this.backOfficeRoles.find((r) => r.name === role.name);
      if (roleRights) {
        this.selectedRights = roleRights.rights;
      }
      this.isDetailsShow = true;
    },

    createRole() {
      if (this.newRole.trim() !== "") {
        const newRoleObj = { name: this.newRole };
        this.backOfficeRoles.push(newRoleObj);
        this.newRole = "";
      }
    },
    close() {
      this.$emit("close");
    },
    cardHeight() {
      const frontOfficeRolesHeight = this.frontOfficeRoles.length * 3.1;
      const backOfficeRolesHeight = this.backOfficeRoles.length * 3.1;
      const totalHeight = frontOfficeRolesHeight + backOfficeRolesHeight;
      const minHeight = 20;
      return Math.max(totalHeight, minHeight);
    },
  },
};
</script>

<style scoped>
.img {
  margin-top: 5px;
  margin-right: 4px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

/* .editRoleModal .modal-body {
    overflow: unset !important;
  
} */
li::marker {
  content: "";
}
.roleManagementContainer {
  height: 66vh;
  max-height: 50%;
  width: 50vw;
  display: flex;
  flex-direction: column;
}

.role--btns {
  background-color: white;
  padding: 15px;
  z-index: 11;
  height: 10%;
  padding: 15px;
  border: 1px solid #c5c5c5;
  border-top: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.add-role {
  background-color: #0071ce;
  border-radius: 0px 0px 0px 25px;
  height: 40px;
  color: white;
  margin-top: 10px;
}
.save_role {
  background-color: #0071ce;
  border-radius: 0px 0px 0px 25px;
  color: white;
  height: 35px;
}
.highlighted {
  position: relative;
  color: #0071ce;
  /* z-index: 1; */
}
.highlighted::after {
  background-color: #e7f0fa;
  border-radius: 0;
  position: absolute;
  top: 0;
  left: -200px;
  width: 100vw;
  height: 100%;
  content: "";
  z-index: 0;
}
.highlighted i {
  color: #0071ce;
  z-index: 13;
}
.highlighted span {
  color: #0071ce;
  z-index: 13;
}
.orgProjectDropdown {
  width: max-content;
  position: relative;
  bottom: 20px;
  left: 20px;
}
.btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.front-office-card {
  padding: 20px;
  width: 50%;
  /* width: 22rem; */
  /* margin-left: 15px; */
  background-color: white;
  /* box-shadow: 3px 4px 30px hsla(0, 0%, 50.6%, 0.534); */
  min-width: auto;
  border: 1px solid #c5c5c5;
  overflow: auto;
}
.role-card {
  height: 100%;
  width: 50%;
  background-color: white;
  /* box-shadow: 23px 4px 30px hsla(0, 0%, 50.6%, 0.534); */
  padding: 20px 0 0 25px;
  overflow: auto;
  position: relative;
  min-width: auto;
  display: flex;
  flex-direction: column;
  /* padding: 0 9px; */
  border: 1px solid #c5c5c5;
  border-left: 0;
  /* z-index: 15; */
}
@media (max-width: 1008px) {
  .roleManagementContainer {
    width: 70%;
    margin: 0 1rem;
  }
}
@media (max-width: 1133px) {
  .orgProjectDropdown {
    width: max-content;
    position: relative;
    top: 0;
    left: 20px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s, transform 0.3s ease-in;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
.close-rights-role {
  display: none;
}
@media (max-width: 853px) {
  .roleManagementContainer {
    width: 75%;
    margin: 15px;
  }
}
@media (max-width: 853px) {
  .roleManagementContainer {
    width: 95%;
    height: 600px;
  }
}
@media (max-width: 608px) {
  .highlighted span,
  .highlighted i {
    z-index: 0;
  }
  .role-card-mobile {
    position: absolute !important;
    left: 0;
    margin: 0;
    padding: 0;
    min-width: auto !important;
    border: 0 !important;
  }
  .close-rights-role {
    display: flex;
    color: #0071ce;
  }
  .front-office-card {
    margin: 0;
    padding: 0;
    width: 100%;
    border: none;
  }
  .roleManagementContainer {
    width: 100%;
    height: calc(100vh - 200px);
  }
  .role-card-mobile {
    width: inherit;
    height: 90%;
    border-left: unset;
    border: 1px solid #c5c5c5;
    z-index: 0;
  }
  .role--btns {
    /* margin: 0 1rem; */
    z-index: 16;
  }
  h1 {
    font-size: 19px;
    padding: 0 16px !important;
    margin: 0;
    height: unset;
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
@media (max-width: 425px) {
  .roleManagementContainer {
    margin: unset;
    height: calc(100vh - 200px);
  }
  .role-card-mobile {
  }
}
</style>
