<template>
  <div class="organizationPage">
    <h1
      style="padding: 0 !important"
      :style="isMobile ? 'font-size:2em;' : 'font-size: 3em;'"
    >
      {{ $t("Organization") }}
    </h1>
    <div class="organization_container position-relative">
      <div class="d-flex justify-content-between align-items-start orgheader">
        <div class="span-switch">
          <span
            v-if="doesHaveRight('viewMembers')"
            @click="switchToUsersView"
            :class="{ active: viewMode === 'users' }"
            class="filter"
            >{{ $t("Users View") }}</span
          >
          <span
            v-if="doesHaveRight('viewLevelsr')"
            @click="switchToOrgView"
            :class="{ active: viewMode === 'organization' }"
            class="filter"
            >{{ $t("Organization View") }}</span
          >
        </div>

        <div>
          <div class="buttons">
            <el-dropdown
              ref="export"
              trigger="contextmenu"
              style="
                background-color: white;
                border: 1px solid rgb(195, 195, 195);
                padding: 9px;
                display: flex;
                align-items: center;
              "
            >
              <span class="el-dropdown-link" @click="showClick('export')">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5075 1.67785C6.75685 0.600578 8.3503 0.0056151 10 0.000442247C11.5951 -0.0182712 13.14 0.557489 14.3338 1.61554C15.5275 2.6736 16.2846 4.13816 16.4575 5.72387C17.4299 5.85091 18.3237 6.32476 18.9746 7.05826C19.6255 7.79176 19.9896 8.73559 20 9.71615C19.9855 10.8001 19.5411 11.8339 18.7645 12.5903C17.9879 13.3467 16.9428 13.7637 15.8588 13.7497H12.5C12.3342 13.7497 12.1753 13.6838 12.0581 13.5666C11.9408 13.4494 11.875 13.2905 11.875 13.1247C11.875 12.959 11.9408 12.8 12.0581 12.6828C12.1753 12.5656 12.3342 12.4997 12.5 12.4997H15.86C16.6123 12.5135 17.3392 12.228 17.8811 11.706C18.423 11.1841 18.7355 10.4684 18.75 9.71615C18.7359 8.96348 18.4233 8.24725 17.8811 7.725C17.3389 7.20275 16.6115 6.91725 15.8588 6.9313H15.2338V6.30634C15.2222 5.6307 15.0776 4.96395 14.8083 4.34418C14.5391 3.72441 14.1503 3.16375 13.6643 2.69424C13.1783 2.22473 12.6045 1.85556 11.9758 1.60782C11.3471 1.36008 10.6757 1.23862 10 1.25037C8.64985 1.25577 7.34605 1.74339 6.32375 2.6253C5.92022 2.93174 5.58404 3.31791 5.3361 3.75979C5.08816 4.20167 4.93376 4.68982 4.8825 5.1939V5.75012L4.32625 5.81137C3.49253 5.89075 2.71747 6.27514 2.14975 6.89081C1.58203 7.50648 1.26163 8.31007 1.25 9.14743C1.26643 10.0529 1.6419 10.9148 2.29382 11.5435C2.94574 12.1722 3.82071 12.5161 4.72625 12.4997H7.5C7.66576 12.4997 7.82473 12.5656 7.94194 12.6828C8.05915 12.8 8.125 12.959 8.125 13.1247C8.125 13.2905 8.05915 13.4494 7.94194 13.5666C7.82473 13.6838 7.66576 13.7497 7.5 13.7497H4.72625C3.48929 13.7657 2.2966 13.2899 1.41034 12.4269C0.52408 11.5639 0.0167969 10.3843 0 9.14743C0.00974978 8.09196 0.379597 7.07144 1.04834 6.25477C1.71708 5.43809 2.64463 4.87421 3.6775 4.65643C3.92986 3.4839 4.57557 2.43291 5.5075 1.67785Z"
                    fill="#434343"
                  />
                  <path
                    d="M9.55731 19.8167C9.61537 19.8749 9.68434 19.9211 9.76027 19.9526C9.8362 19.9841 9.9176 20.0003 9.99981 20.0003C10.082 20.0003 10.1634 19.9841 10.2394 19.9526C10.3153 19.9211 10.3843 19.8749 10.4423 19.8167L14.1923 16.067C14.3097 15.9496 14.3756 15.7904 14.3756 15.6245C14.3756 15.4585 14.3097 15.2994 14.1923 15.182C14.075 15.0646 13.9158 14.9987 13.7498 14.9987C13.5838 14.9987 13.4247 15.0646 13.3073 15.182L10.6248 17.8656V6.87497C10.6248 6.70921 10.559 6.55025 10.4418 6.43305C10.3245 6.31584 10.1656 6.25 9.99981 6.25C9.83405 6.25 9.67508 6.31584 9.55787 6.43305C9.44066 6.55025 9.37481 6.70921 9.37481 6.87497V17.8656L6.69231 15.182C6.6342 15.1239 6.56522 15.0778 6.48929 15.0464C6.41337 15.0149 6.33199 14.9987 6.24981 14.9987C6.16763 14.9987 6.08626 15.0149 6.01033 15.0464C5.93441 15.0778 5.86542 15.1239 5.80731 15.182C5.7492 15.2401 5.70311 15.3091 5.67166 15.385C5.64021 15.4609 5.62402 15.5423 5.62402 15.6245C5.62402 15.7067 5.64021 15.788 5.67166 15.8639C5.70311 15.9399 5.7492 16.0088 5.80731 16.067L9.55731 19.8167Z"
                    fill="#434343"
                  />
                </svg>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="getOrganizationLevels">{{
                    $t("Organization levels")
                  }}</el-dropdown-item>
                  <el-dropdown-item @click="getOrganizationMembers">{{
                    $t("Organization members")
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <CButton
              v-if="userStore.isAdminUser"
              color="dark"
              @click="openEditRolesModal()"
              class="edit-role-btn"
              :disabled="loading"
              style="
                background-color: white;
                color: black;
                border: 1px solid rgb(195, 195, 195);
              "
            >
              {{ $t("Edit Roles") }}
            </CButton>
            <el-dropdown
              ref="memberAdd"
              trigger="contextmenu"
              type="primary"
              style="
                padding: 12px 12px;
                display: flex;
                align-items: center;
                border-radius: 0px 0px 0px 25px;
                background: #0071ce;
                color: white;
              "
            >
              <span
                class="el-dropdown-link d-flex gap-1 align-items-center justify-content-center"
                style="width: 105px"
                @click="showClick('memberAdd')"
              >
                {{ $t("Add member") }}
                <i class="fa fa-chevron-down"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    @click="openUniqueMember"
                    v-if="doesHaveRight('addUniqueUser')"
                    >{{ $t("Unique member") }}</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click="openMultipleMember"
                    v-if="doesHaveRight('addBulkUsers')"
                    >{{ $t("Multiple members") }}</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click="openMultipleLevels"
                    v-if="doesHaveRight('addBulkUsers')"
                    >{{ $t("Multiple levels") }}</el-dropdown-item
                  >
                  <el-dropdown-item @click="getTemplate">{{
                    $t("Get members template")
                  }}</el-dropdown-item>
                  <el-dropdown-item @click="getLevelsTemplate">{{
                    $t("Get levels template")
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div v-if="viewMode === 'users'">
          <div>
            <div class="filtersContainerRow">
              <el-input
                v-model="firstNameFilter"
                placeholder="First Name"
                style="width: 100%; height: 40px"
                @blur="filtersChanged('firstname')"
              />
              <el-input
                v-model="lastNameFilter"
                placeholder="Last Name"
                style="width: 100%; height: 40px"
                @blur="filtersChanged('lastname')"
              />
              <!-- <el-input
                v-model="emailFilter"
                placeholder="Email"
                style="width: 100%; height: 40px"
                @blur="filtersChanged('email')"
              /> -->
              <el-select
                v-model="emailFilter"
                placeholder="Email"
                style="width: 100%"
                clearable
                collapse-tags
                collapse-tags-tooltip
                multiple
                filterable
                @change="filtersChanged('email')"
              >
                <el-option
                  v-for="item in emailOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
              <el-select
                v-model="localizationFilter"
                placeholder="Localisation"
                style="width: 100%"
                clearable
                collapse-tags
                collapse-tags-tooltip
                multiple
                filterable
                @change="filtersChanged('localisation')"
              >
                <el-option
                  v-for="item in localizationOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </div>
            <div class="filtersContainerRow">
              <el-select
                v-model="levelFilter"
                placeholder="Levels"
                style="width: 100%"
                clearable
                collapse-tags
                collapse-tags-tooltip
                multiple
                filterable
                @change="filtersChanged('level')"
              >
                <el-option
                  v-for="item in levelsOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
              <el-select
                v-model="jobDescriptionFilter"
                placeholder="Job Description"
                style="width: 100%"
                clearable
                collapse-tags
                collapse-tags-tooltip
                multiple
                filterable
                @change="filtersChanged('job_description')"
              >
                <el-option
                  v-for="item in jobDescriptionOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
              <el-select
                v-model="expertiseFilter"
                placeholder="Expertise"
                style="width: 100%"
                clearable
                collapse-tags
                collapse-tags-tooltip
                multiple
                filterable
                @change="filtersChanged('expertise')"
              >
                <el-option
                  v-for="item in expertiseOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
              <el-select
                v-model="roleTypeFilter"
                placeholder="Role Type"
                style="width: 100%"
                clearable
                collapse-tags
                collapse-tags-tooltip
                multiple
                filterable
                @change="filtersChanged('role_type')"
              >
                <el-option
                  v-for="item in roleTypeOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </div>
          </div>
          <OrganizationTable
            :tableFields="
              this.$i18n.locale === 'fr' ? tableFieldsFr : tableFields
            "
            :tableItems="tableItems"
            :orgMembers="orgMembers"
            :newMember="newMember"
            :displayedItems="displayedItems"
            :totalPages="totalPages"
            :perPageOptions="perPageOptions"
            :perPage="perPage"
            :currentPage="currentPage"
            :isLoading="isTableLoading"
            :availableProjectMembers="availableProjectMembers"
            :addMemberDisabled="addMemberDisabled"
            :categoryOptions="categoryOptions"
            :statusOptions="statusOptions"
            :itEquipmentOptions="itEquipmentOptions"
            :jobDescriptionOptions="jobDescriptionOptions"
            :levelOptions="levelOptions"
            :projectLevelOptions="projectLevelOptions"
            :orgViewData="orgViewData"
            :projectData="projectData"
            :allOrgViewData="allOrgViewData"
            :projectRolesOptions="projectRolesOptions"
            @changePerPage="changePerPage"
            @previousPage="previousPage"
            @nextPage="nextPage"
            @handlePageChange="handlePageChange"
            @refreshData="refreshData"
          ></OrganizationTable>
          <OgranizationBuildProjectTeamModal
            :showBuildProjectTeamModal="showBuildProjectTeamModal"
            :projects="projects"
            :availableProjectMembers="availableProjectMembers"
            @close-build-project-team-modal="closeBuildProjectTeamModal"
            @getProjectsData="getProjectsData"
          />
        </div>
        <div v-else>
          <OrganizationView
            :isLoading="isTableLoading"
            :orgViewData="orgViewData"
            :orgMembers="orgMembers"
            :newMember="newMember"
            :projects="projects"
            :projectData="projectData"
            :expandedLevels="expandedLevels"
            :allLevels="allLevels"
            :projectLevelOptions="projectLevelOptions"
            :allLevelsInOrgAndProject="allLevelsInOrgAndProject"
          />
        </div>
      </div>

      <OrganizationEditModal
        :show="showEditModal"
        @close="toggleEditModal"
        :orgName="orgToEdit"
        :chosenProjectToEdit="chosenProjectToEdit"
        :orgsWithProjects="orgsWithProjects"
        @updateOrgsList="refreshEverything"
      />
      <OrganizationDeleteModal
        :show="showDeleteModal"
        @close="
          showDeleteModal = false;
          this.chosenProjectToEdit = '';
        "
        :orgName="orgToDelete"
        :chosenProjectToDelete="chosenProjectToDelete"
        :orgsWithProjects="orgsWithProjects"
        @updateOrgsList="refreshEverything"
      />
      <OrganizationCreateModal
        :show="showCreateModal"
        @close="toggleCreateModal"
        @updateOrgsList="refreshData"
        @createOrganization="createOrganization"
      />
      <!--Add unique member-->

      <!--Add multiple member-->
      <div>
        <CModal :show="showMultipleMember" :draggable="false">
          <div class="modal-body">
            <FileDragDrop
              title="Add members"
              label=""
              @upload="(uploadFiles) => handleUploadMembers(uploadFiles, 1)"
            />
          </div>
          <template v-slot:header>
            <h3>{{ $t("Add multiple members") }}</h3>
            <CButton @click="closeMultipleMember">
              <i class="fa fa-times"></i>
            </CButton>
          </template>
          <template v-slot:footer>
            <CButton @click="closeMultipleMember" color="link">{{
              $t("Close")
            }}</CButton>
          </template>
        </CModal>
      </div>

      <!--Add multiple levels-->
      <div>
        <CModal :show="showMultipleLevels" :draggable="false">
          <div class="modal-body">
            <FileDragDrop
              title="Add levels"
              label=""
              @upload="(uploadFiles) => handleUploadLevels(uploadFiles, 1)"
            />
          </div>
          <template v-slot:header>
            <h3>{{ $t("Add multiple levels") }}</h3>
            <CButton @click="closeMultipleLevels">
              <i class="fa fa-times"></i>
            </CButton>
          </template>
          <template v-slot:footer>
            <CButton @click="closeMultipleLevels" color="link">{{
              $t("Close")
            }}</CButton>
          </template>
        </CModal>
      </div>

      <AddUniqueMember
        :show="showUniqueMember"
        :newMember="newMember"
        :addMemberDisabled="addMemberDisabled"
        :origin="'addNew'"
        :levelOptions="levelOptions"
        :projectLevelOptions="projectLevelOptions"
        :categoryOptions="categoryOptions"
        :statusOptions="statusOptions"
        :itEquipmentOptions="itEquipmentOptions"
        :jobDescriptionOptions="jobDescriptionOptions"
        :isAddingMember="isAddingMember"
        :projectData="projectData"
        :projectRolesOptions="projectRolesOptions"
        @close="closeAddUniqueMember"
      />
      <EditRolesModal
        :show="showEditRolesModal"
        :projectData="projectData"
        @close="showEditRolesModal = false"
      />
    </div>
  </div>
</template>

<script>
import OrganizationDeleteModal from "./organizationSelect/modals/OrganizationDeleteModal.vue";
import OrganizationCreateModal from "./organizationSelect/modals/OrganizationCreateModal.vue";
import OrganizationEditModal from "./organizationSelect/modals/OrganizationEditModal.vue";
import EditRolesModal from "./organizationSelect/modals/EditRolesModal.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";

import AddUniqueMember from "./organizationSelect/modals/AddUniqueMember.vue";
import OrganizationSelect from "./organizationSelect/OrganizationSelect.vue";
import OgranizationBuildProjectTeamModal from "./components/OgranizationBuildProjectTeamModal.vue";
import OrganizationTable from "./components/OrganizationTable.vue";
import OrganizationView from "./components/OrganizationView.vue";
import OrganizationViewTree from "./components/OrganizationViewTree.vue";
import { OrganizationsService } from "@/services/organizations";
import { ProjectsService } from "@/services/projects";
import { UsersService } from "@/services/users";
import { FilesService } from "@/services/files";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { ContentLoader } from "vue-content-loader";
import Message from "vue-m-message";
import { showMessage } from "../../utils/helpers";
import { mapGetters, mapState } from "vuex";
import { useGlobalStore } from "../../stores/store";
import { useUserStore } from "@/stores/UserStore";
import { mapWritableState } from "pinia";
import { ElLoading } from "element-plus";

export default {
  name: "Organization",
  components: {
    vSelect,
    Treeselect,
    OrganizationTable,
    OrganizationView,
    OgranizationBuildProjectTeamModal,
    OrganizationSelect,
    OrganizationEditModal,
    OrganizationDeleteModal,
    OrganizationCreateModal,
    EditRolesModal,
    AddUniqueMember,
    OrganizationViewTree,
    ContentLoader,
    OrganizationSelector,
  },
  data() {
    return {
      userStore: useUserStore(),
      globalStore: useGlobalStore(),
      showEditRolesModal: false,
      loading: true,
      chosenProjectToDelete: "",
      allLevels: new Set(),
      expandedLevels: new Set(),
      levelOptions: [],
      projectLevelOptions: [],
      chosenProjectToEdit: {
        organization: "",
        project: "",
      },
      showAddRoleModal: false,
      ResponsibleChecked: false,
      categoryOptions: [],
      statusOptions: [],
      jobDescriptionOptions: [],
      itEquipmentOptions: [],
      isTableLoading: false,
      isOrgListLoading: false,
      availableProjectMembers: [],
      orgToDelete: null,
      orgToEdit: null,
      showDeleteModal: false,
      showEditModal: false,
      showCreateModal: false,
      orgList: [],
      orgListExpanded: false,
      tableFields: [
        {
          key: "firstname",
          label: "First name",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "lastname",
          label: "Last name",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "email",
          label: "E-mail",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "n1",
          label: "Direct report",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "category",
          label: "Category",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "job_description",
          label: "Job description",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "direction",
          label: "Direction",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "pole",
          label: "Pole",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "location",
          label: "Location",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "it_equipment",
          label: "IT equipment",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          thStyle: { width: "25%" },
        },
      ],
      tableFieldsFr: [
        {
          key: "firstname",
          label: "Prénom",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "lastname",
          label: "Nom",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "email",
          label: "E-mail",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "n1",
          label: "Direct report",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "category",
          label: "Catégorie",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "status",
          label: "Statut",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "job_description",
          label: "Description du poste",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "direction",
          label: "Direction",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "pole",
          label: "Pôle",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "location",
          label: "Localisation",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "it_equipment",
          label: "Equipement IT",
          sortable: false,
          thStyle: { width: "25%" },
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          thStyle: { width: "25%" },
        },
      ],
      projects: [],
      tableItems: [],
      viewMode: "users",
      showOptions: false,
      perPage: 50,
      currentPage: 1,
      perPageOptions: [25, 50, 100, 200, "All"],
      displayedItems: 0,
      showBuildProjectTeamModal: false,
      showUniqueMember: false,
      newMember: {
        project_name: "",
        first_name: "",
        last_name: "",
        email: "",
        n1: null,
        localisation: "",
        phone: "",
        roles: [
          {
            job_description: "",
            admin: false,
            responsible: false,
            level_name: "",
          },
        ],
      },
      showMultipleMember: false,
      showMultipleLevels: false,
      user: null,
      orgsWithProjects: [],
      orgViewData: [],
      projectData: [],
      isAddingMember: false,
      orgMembers: [],
      expandAll: true,
      allLevelsInOrgAndProject: [],
      allOrgViewData: [],
      organizationPermissions: [],
      projectRolesOptions: [],
      firstNameFilter: "",
      lastNameFilter: "",
      emailFilter: [],
      localizationFilter: [],
      levelFilter: [],
      jobDescriptionFilter: [],
      expertiseFilter: [],
      roleTypeFilter: [],
      localizationOptions: [],
      emailOptions: [],
      levelsOptions: [],
      jobDescriptionOptions: [],
      expertiseOptions: [],
      roleTypeOptions: ["Organization", "Project"],
      filters: {
        firstname: "",
        lastname: "",
        email: "",
        localisation: "",
        level: [],
        job_description: [],
        expertise: [],
        role_type: [],
      },
    };
  },

  computed: {
    ...mapState({
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
    }),
    ...mapWritableState(useGlobalStore, {
      isMobile: "isMobile",
    }),
    totalPages() {
      if (this.orgMembers) {
        return Math.ceil(this.orgMembers.length / this.perPage);
      }
    },
    addMemberDisabled() {
      const requiredFields = [
        "first_name",
        "last_name",
        "email",
        "localisation",
        "roles",
      ];

      return requiredFields.some((field) => {
        const value = this.newMember[field];

        // Check if the field is a string and is empty after trimming
        if (typeof value === "string") {
          return value.trim() === "";
        }

        // Check if the field is an object, specifically the roles object
        if (field === "roles" && typeof value === "object") {
          return Object.values(value).some((obj) => {
            // Check for nested properties within roles, excluding "expertise"
            return Object.keys(obj).some((prop) => {
              if (prop === "expertise") {
                // Skip checking for "expertise"
                return false;
              }
              if (typeof obj[prop] === "boolean") {
                return false;
              } else {
                return obj[prop].trim().length === 0;
              }
            });
          });
        }

        return false;
      });
    },
  },
  async mounted() {
    this.organizationPermissions = this.userStore.currentPermissions[0].find(
      (p) => p.name === "Organization"
    ).permissions;
    this.user = this.userData;
    this.isTableLoading = true;
    this.isOrgListLoading = true;
    this.refreshEverything();
  },
  watch: {
    currentPage(newPage) {
      const start = (newPage - 1) * this.perPage;
      const end = Math.min(start + this.perPage, this.tableItems.length);
      this.displayedItems = end - start;
    },
    perPage(newPerPage) {
      this.displayedItems = Math.min(newPerPage, this.tableItems.length);
    },
  },
  methods: {
    filtersChanged(type) {
      const filterMap = {
        firstname: "firstNameFilter",
        lastname: "lastNameFilter",
        email: "emailFilter",
        localisation: "localizationFilter",
        level: "levelFilter",
        job_description: "jobDescriptionFilter",
        expertise: "expertiseFilter",
        role_type: "roleTypeFilter",
      };

      const currentValue = this.filters[type];
      const newValue = this[filterMap[type]];

      const hasChanged = Array.isArray(newValue)
        ? newValue.length !== currentValue.length ||
          !newValue.every((v, i) => v === currentValue[i])
        : newValue !== currentValue;

      if (hasChanged) {
        this.filters[type] = newValue;
        this.getTableData();
      }
    },
    changeProjectRoleOptions(new_roles) {
      this.projectRolesOptions = new_roles.filter((role) => {
        if (role.scope === "project") {
          return role.name;
        }
      });
    },
    doesHaveRight(rightName) {
      if (
        this.organizationPermissions &&
        this.organizationPermissions.length > 0
      ) {
        return this.organizationPermissions.find(
          (right) => right.name === rightName
        ).status;
      }
    },
    handleCollapseExpandAll() {
      this.expandAll = !this.expandAll;
    },
    openEditRolesModal() {
      this.showEditRolesModal = true;
    },
    showClick(val) {
      if (val == "memberAdd") {
        if (!this.$refs.memberAdd) return;
        this.$refs.memberAdd.handleOpen();
      } else {
        if (!this.$refs.export) return;
        this.$refs.export.handleOpen();
      }
    },
    closeAddUniqueMember() {
      this.addMemberDisabled = false;
      this.isAddingMember = false;
      this.showUniqueMember = false;
      // this.newMember=Object.assign(this.newMember,{})
      this.newMember = {
        project_name: "",
        first_name: "",
        last_name: "",
        email: "",
        n1: null,
        localisation: "",
        phone: "",
        roles: [
          {
            job_description: "",
            admin: false,
            responsible: false,
            level_name: "",
          },
        ],
      };
    },
    async getOrganizationLevels() {
      const { data, res } = await OrganizationsService.exportOrgLevels({
        name:
          this.currentOrganization.name ||
          this.currentOrganization.organization,
        project: this.currentProject.name,
      });
      if (res.status === 200) {
        showMessage("success", "Organization levels downloaded successfully !");
      } else {
        showMessage("error", "Failed to download organization levels !");
      }
    },
    async getOrganizationMembers() {
      const { data, res } = await OrganizationsService.exportOrgMembers({
        organization_name:
          this.currentOrganization.name ||
          this.currentOrganization.organization,
        project_name: this.currentProject.name,
      });
      if (res.status === 200) {
        showMessage(
          "success",
          "Organization members downloaded successfully !"
        );
      } else {
        showMessage("error", "Failed to download organization members !");
      }
    },
    setIsLoadingTrue() {
      this.isTableLoading = true;
    },
    async list_organizations_and_projects(isFromRefreshEverything) {
      const loading =
        !isFromRefreshEverything &&
        ElLoading.service({ fullscreen: true, background: "#0a1f8f45" });

      const data = await OrganizationsService.list_organizations_and_projects(
        "organization"
      );
      if (data.data.success) {
        this.$store.commit("setIncludedOrgs", data.data);
        const selectedOrganizationName =
          this.currentOrganization.name ||
          this.currentOrganization.organization ||
          this.currentOrganization;
        const selectedOrganizationTree = this.includedOrgs.data
          .filter(
            (org) =>
              org.organization === selectedOrganizationName &&
              org.projects[0].name === this.currentProject.name
          )
          .map((org) => org.tree)[0];

        const selectedOrganizationProject = this.includedOrgs.data
          .filter(
            (org) =>
              org.organization === selectedOrganizationName &&
              org.projects[0].name === this.currentProject.name
          )
          .map((org) => org.projects[0])[0];
        this.projectData = selectedOrganizationProject;
        this.projectRolesOptions = this.projectData.roles.filter((role) => {
          if (role.scope === "project") {
            return role.name;
          }
        });
        this.orgViewData = [...selectedOrganizationTree];
        this.allOrgViewData = [
          ...selectedOrganizationTree,
          ...this.projectData.tree,
        ];

        this.addNonEmptyChildrenToExpandedLevels(this.orgViewData);
        this.addAllLevels(this.orgViewData);
        this.addNonEmptyChildrenToExpandedLevels(this.projectData?.tree);
        this.addAllLevels(this.projectData?.tree);
        this.isOrgListLoading = false;
        this.isTableLoading = false;
      }
      !isFromRefreshEverything && loading.close();
    },
    addNonEmptyChildrenToExpandedLevels(data) {
      try {
        for (let item of data) {
          if (item.children && item.children.length > 0) {
            this.expandedLevels.add(item.level_name);
            this.addNonEmptyChildrenToExpandedLevels(item.children);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    addAllLevels(data) {
      try {
        for (let item of data) {
          this.allLevels.add(item.level_name);
          if (item.children) {
            this.addAllLevels(item.children);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleCloseOrglist() {
      this.orgListExpanded = false;
    },
    async refreshData() {
      await this.getOrgsList(true);
      await this.getTableData(true);
      await this.getProjectsData(true);
      await this.getAvailableProjectMembers(true);
      await this.getMembers(true);
    },

    async getMembers(isFromRefreshEverything) {
      const loading =
        !isFromRefreshEverything &&
        ElLoading.service({ fullscreen: true, background: "#0a1f8f45" });

      const { data, res } = await OrganizationsService.list_org_members(
        {
          organization_name:
            this.currentOrganization.name ||
            this.currentOrganization.organization ||
            this.currentOrganization,
          project_name: this.currentProject.name,
        },
        "organization"
      );
      this.availableProjectMembers = data.data.map((item) => ({
        id: `${item.lastname}_${item.firstname}`,
        label: `${item.lastname} ${item.firstname}`,
        email: `${item.email}`,
      }));
      !isFromRefreshEverything && loading.close();
    },

    async createOrganization(orgToCreate, projectToCreate) {
      let exists = false;

      for (let org of this.orgsWithProjects) {
        if (org.organization === orgToCreate) {
          for (let project of org.projects) {
            if (project.name === projectToCreate) {
              exists = true;
              break;
            }
          }
        }
        if (exists) {
          break;
        }
      }
      if (exists) {
        showMessage("error", "Organization/Project already exists");
        return;
      }

      const { data, res } = await OrganizationsService.create({
        name: orgToCreate,
        project: projectToCreate,
      });
      if (!data.success) {
        showMessage("error", "Error creating new organization!");
      } else {
        showMessage("success", "Successfully Created Organization");

        this.toggleCreateModal();
        this.changeOrgName(orgToCreate);
        this.changeProjectName(projectToCreate);
        this.refreshEverything();
      }
    },
    setLoadingTrue() {
      this.loading = true;
      this.isTableLoading = true;
      this.orgListExpanded = false;
    },
    async refreshEverything() {
      this.allLevels = new Set();

      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });

      await this.list_organizations_and_projects(true);
      await this.getTableData(true);
      await this.getProjectsData(true);
      await this.getAvailableProjectMembers(true);
      await this.getMembers(true);
      await this.getFilters(true);
      loading.close();
    },
    async getFilters(isFromRefreshEverything) {
      const loading =
        !isFromRefreshEverything &&
        ElLoading.service({ fullscreen: true, background: "#0a1f8f45" });

      const { data, res } = await OrganizationsService.getFilters();
      if (res.ok) {
        this.localizationOptions = data.data.unique_org_localisation;
        this.levelsOptions = data.data.unique_org_level;
        this.jobDescriptionOptions = data.data.unique_org_job_description;
        this.expertiseOptions = data.data.unique_org_expertise;
        this.emailOptions = data.data.unique_org_email;
      }
      !isFromRefreshEverything && loading.close();
    },
    async getAvailableProjectMembers(isFromRefreshEverything) {
      const loading =
        !isFromRefreshEverything &&
        ElLoading.service({ fullscreen: true, background: "#0a1f8f45" });

      const { data, res } = await ProjectsService.list_avaliable_members({
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization ||
          this.currentOrganization,
      });
      this.availableProjectMembers = data.data.map((item) => ({
        id: `${item.lastname}_${item.firstname}`,
        label: `${item.lastname} ${item.firstname}`,
        email: `${item.email}`,
      }));
      !isFromRefreshEverything && loading.close();
    },
    async getProjectsData(isFromRefreshEverything) {
      const loading =
        !isFromRefreshEverything &&
        ElLoading.service({ fullscreen: true, background: "#0a1f8f45" });

      const { data, res } = await ProjectsService.list_projects({
        organization_name:
          this.currentOrganization.name ||
          this.currentOrganization.organization ||
          this.currentOrganization,
      });
      this.projects = data.data;
      !isFromRefreshEverything && loading.close();
    },

    async getTableData(isFromRefreshEverything) {
      const loading =
        !isFromRefreshEverything &&
        ElLoading.service({ fullscreen: true, background: "#0a1f8f45" });

      try {
        this.isTableLoading = true;
        const { data, res } = await OrganizationsService.details({
          name:
            this.currentOrganization.name ||
            this.currentOrganization.organization ||
            this.currentOrganization,
          project: this.currentProject.name,
          filters: this.filters,
        });

        const OrganizationLevels = Array.isArray(data.data.tree)
          ? data.data.tree
          : [];
        const ProjectLevels = Array.isArray(data.data.project_trees)
          ? data.data.project_trees
          : [];
        this.allLevelsInOrgAndProject =
          OrganizationLevels.concat(ProjectLevels);
        this.orgMembers = data.data.members;
        this.tableItems = OrganizationLevels;
        this.displayedItems = Math.min(this.perPage, OrganizationLevels.length);
        this.levelOptions = this.getUniqueFieldValues(OrganizationLevels);
        this.projectLevelOptions = this.getUniqueFieldValues(ProjectLevels);
      } catch (error) {
        console.error("Error fetching table data:", error);
        showMessage("error", "Error getting table data!");
        this.loading = false;
      } finally {
        this.isTableLoading = false;
        this.loading = false;
      }
      !isFromRefreshEverything && loading.close();
    },
    getUniqueOptions(orgMembers, property) {
      return [...new Set(orgMembers.map((member) => member[property]))];
    },
    getUniqueFieldValues(data) {
      this.categoryOptions = this.getUniqueOptions(this.orgMembers, "category");
      this.statusOptions = this.getUniqueOptions(this.orgMembers, "status");
      this.itEquipmentOptions = this.getUniqueOptions(
        this.orgMembers,
        "it_equipment"
      );
      this.jobDescriptionOptions = [
        ...new Set(
          this.orgMembers.flatMap((member) => {
            return member.roles.map((role) => {
              return role.job_description;
            });
          })
        ),
      ];

      const uniqueNames = new Set();
      data.forEach((item) => {
        uniqueNames.add(item.name);
      });
      return Array.from(uniqueNames);
    },

    toggleEditModal(orgName, projectName) {
      this.orgToEdit = orgName;
      this.chosenProjectToEdit.organization = orgName;
      this.chosenProjectToEdit.project = projectName;
      this.showEditModal = !this.showEditModal;

      if (!this.showEditModal) {
        this.chosenProjectToEdit = {
          organization: "",
          project: "",
        };
      }
    },

    toggleDeleteModal(orgName, projectName) {
      this.orgToDelete = orgName;
      this.chosenProjectToDelete = projectName;
      this.showDeleteModal = true;
    },

    toggleCreateModal() {
      this.showCreateModal = !this.showCreateModal;
    },

    toggleOrgList(event) {
      event.stopPropagation();
      this.orgListExpanded = !this.orgListExpanded;
    },

    switchToUsersView() {
      this.viewMode = "users";
    },

    switchToOrgView() {
      this.viewMode = "organization";
    },

    toggleOptions() {
      this.showOptions = !this.showOptions;
    },

    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage;
      }
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage;
      }
    },

    changePerPage(value) {
      if (value === "All") {
        this.perPage = this.orgMembers.length;
        this.currentPage = 1;
      } else {
        this.perPage = value;
      }
      this.currentPage = 1;
      this.displayedItems = Math.min(this.orgMembers.length, this.perPage);
      this.totalPages = Math.ceil(this.orgMembers.length / this.perPage);
    },

    openBuildProjectTeamModal() {
      this.showBuildProjectTeamModal = true;
    },

    closeBuildProjectTeamModal() {
      this.showBuildProjectTeamModal = false;
    },

    async getTemplate() {
      await OrganizationsService.bulk_members_template({
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization ||
          this.currentOrganization,
        project: this.currentProject.name,
      });
      this.showOptions = false;
    },

    async getLevelsTemplate() {
      await OrganizationsService.bulk_levels_template({
        org_name:
          this.currentOrganization.name ||
          this.currentOrganization.organization ||
          this.currentOrganization,
        project_name: this.currentProject.name,
      });
      this.showOptions = false;
    },

    openUniqueMember(location, direction, pole) {
      this.showUniqueMember = true;
      this.showOptions = false;
    },

    closeUniqueMember() {
      this.newMember = {
        first_name: "",
        last_name: "",
        email: "",
        n1: null,
        category: "",
        status: "",
        it_equipment: "",
        phone: "",
        responsible: false,
        admin: false,
        roles: [
          {
            job_description: "",
          },
        ],
      };
      this.roles = [];
      this.showUniqueMember = false;
    },
    async addMemberFromTable(memberRoles, selectedMember) {
      const neededMember = this.orgMembers.filter((member) => {
        return `${member.firstname} ${member.lastname}` === selectedMember;
      });
      let memberToSend = {
        project_name: this.currentProject.name,
        firstname: neededMember[0].firstname,
        lastname: neededMember[0].lastname,
        email: neededMember[0].email,
        n1: neededMember[0].n1,
        phone: neededMember[0].phone,
        localisation: neededMember[0].localisation,
        roles: [],
      };
      neededMember[0].roles.forEach((role) => {
        memberToSend.roles.push({
          job_description: role.job_description,
          admin: role.admin,
          responsible: role.responsible,
          level_name: role.level_name,
          org_id: role.org_id,
          roleType: role.roleType,
        });
      });
      memberToSend.roles.push(memberRoles);
      const { data, res } = await OrganizationsService.edit_member({
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization ||
          this.currentOrganization,
        project: this.currentProject.name,
        email: memberToSend.email,
        user_data: memberToSend,
      });

      if (res.status === 200) {
        const userData = await UsersService.getActiveUserInfo();
        this.$store.commit("setJobsPoints", userData.data.level_points_v2);
        const firstEntry = Object.entries(
          userData.data.current_job_description
        )[0];

        let currentLevel = userData.data.current_level;
        if (currentLevel === "") {
          currentLevel = Object.keys(userData.data.current_job_description)[0];
        }
        this.$store.commit("setJob", firstEntry);

        this.userStore.setCurrentLevel(currentLevel);
        showMessage("success", "Successfully added user");
        this.getTableData();
      }
    },
    async addMember(roles) {
      if (!roles.length) {
        showMessage("error", "Please select at least one role");
        return;
      }
      this.isAddingMember = true;

      this.newMember.project_name = this.currentProject.name;
      this.newMember.roles = roles;

      if (this.newMember.n1 == null) {
        this.newMember.n1 = "";
      }
      const { data, res } = await OrganizationsService.create_member({
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization ||
          this.currentOrganization,
        project: this.currentProject.name,
        member_details: this.newMember,
      });
      if (!data.success) {
        showMessage("error", "Error while creating new user: " + data.detail);
      } else {
        const userData = await UsersService.getActiveUserInfo();
        if (userData.data) {
          this.$store.commit("setUserData", userData.data);
          this.$store.commit("setOrgObj", userData.data.current_organization);
          this.$store.commit("setJobsPoints", userData.data.level_points_v2);
          const firstEntry = Object.entries(
            userData.data.current_job_description
          )[0];
          this.$store.commit("setJob", firstEntry);

          let currentLevel = userData.data.current_level;
          if (currentLevel === "") {
            let currentRole = Object.keys(
              userData.data.current_job_description
            )[0];
            currentLevel = userData.data.level_points_v2.map((level) => {
              if (level.job === currentRole) {
                return level.level;
              }
            })[0];
          }
          this.userStore.setCurrentLevel(currentLevel);
          this.userStore.setUserPoints(userData.data.total_points);
          this.userStore.setUserTrainingPoints(
            userData.data.training_total_points
          );
          this.userStore.setUserTrainingProgress(userData.data.training_points);
          const teamPointsData = userData.data.level_points_v2;
          const neededPointsData = teamPointsData.filter((point) => {
            return point.job === firstEntry[0] && point.level === currentLevel;
          })[0];

          this.userStore.setTeamPoints(neededPointsData);
          this.userStore.setUsersProgress(userData.data.total_progress);
          const teamProgressData = userData.data.level_progress_v2;
          const neededProgressData = teamProgressData.filter((point) => {
            return (
              point.job === firstEntry[0] &&
              point.level === userData.data.current_level
            );
          })[0];
          this.userStore.setAllRolePoints(userData.data.all_roles_points);
          this.userStore.setUsersTeamProgress(neededProgressData);
          this.globalStore.setUpdatedProgress(false);
        }
        showMessage("success", "Successfully added user");

        this.isAddingMember = false;
        this.newMember = {
          project_name: "",
          first_name: "",
          last_name: "",
          email: "",
          n1: null,
          localisation: "",
          phone: "",
          roles: [
            {
              job_description: "",
              admin: false,
              responsible: false,
              level_name: "",
            },
          ],
        };
        this.closeUniqueMember();
        await this.getTableData();
        await this.getProjectsData();
        await this.list_organizations_and_projects();
        await this.getMembers();
      }
    },

    openMultipleMember() {
      this.showMultipleMember = true;
      this.showOptions = false;
    },

    openMultipleLevels() {
      this.showMultipleLevels = true;
      this.showOptions = false;
    },

    closeMultipleMember() {
      this.showMultipleMember = false;
    },

    closeMultipleLevels() {
      this.showMultipleLevels = false;
    },

    async handleUploadMembers(file, fileId) {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });

      try {
        await FilesService.uploadFile(file, fileId);
      } catch (error) {
        showMessage("error", error);
      }

      const { data, res } = await OrganizationsService.bulk_create_members({
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization ||
          this.currentOrganization,
        project: this.currentProject.name,
        file_name: file.name,
      });
      if (!data.success) {
        showMessage("error", data.detail || "You can only upload new members");
      } else {
        if (data.non_eligible_users.length > 0) {
          let message =
            "Failed to create some users: " +
            data.non_eligible_users +
            ". Check your input file and try again.";
          showMessage("warning", message);
        } else {
          const userData = await UsersService.getActiveUserInfo();
          if (userData.data) {
            this.$store.commit("setUserData", userData.data);
            this.$store.commit("setOrgObj", userData.data.current_organization);
            this.$store.commit("setJobsPoints", userData.data.level_points_v2);
            const firstEntry = Object.entries(
              userData.data.current_job_description
            )[0];
            this.$store.commit("setJob", firstEntry);

            let currentLevel = userData.data.current_level;
            if (currentLevel === "") {
              let currentRole = Object.keys(
                userData.data.current_job_description
              )[0];
              currentLevel = userData.data.level_points_v2.map((level) => {
                if (level.job === currentRole) {
                  return level.level;
                }
              })[0];
            }
            this.userStore.setCurrentLevel(currentLevel);
            this.userStore.setUserPoints(userData.data.total_points);
            this.userStore.setUserTrainingPoints(
              userData.data.training_total_points
            );
            this.userStore.setUserTrainingProgress(
              userData.data.training_points
            );
            const teamPointsData = userData.data.level_points_v2;
            const neededPointsData = teamPointsData.filter((point) => {
              return (
                point.job === firstEntry[0] && point.level === currentLevel
              );
            })[0];

            this.userStore.setTeamPoints(neededPointsData);
            this.userStore.setUsersProgress(userData.data.total_progress);
            const teamProgressData = userData.data.level_progress_v2;
            const neededProgressData = teamProgressData.filter((point) => {
              return (
                point.job === firstEntry[0] &&
                point.level === userData.data.current_level
              );
            })[0];
            this.userStore.setAllRolePoints(userData.data.all_roles_points);
            this.userStore.setUsersTeamProgress(neededProgressData);
            this.globalStore.setUpdatedProgress(false);
          }
          showMessage("success", "Users added successfully!");
        }

        this.getTableData();
        this.closeMultipleMember();
        await this.getTableData();
        await this.getProjectsData();
        await this.getAvailableProjectMembers();
        await this.getMembers();
      }
      loading.close();
    },

    async handleUploadLevels(file, fileId) {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });

      try {
        await FilesService.uploadFile(file, fileId);
      } catch (error) {
        showMessage("error", error);
      }

      const { data, res } = await OrganizationsService.bulk_create_levels({
        org_name:
          this.currentOrganization.name ||
          this.currentOrganization.organization ||
          this.currentOrganization,
        project_name: this.currentProject.name,
        file_name: file.name,
      });
      if (!data.success) {
        showMessage("error", data.data);
      } else {
        showMessage("success", "Levels added successfully!");

        this.closeMultipleLevels();
        await this.refreshEverything();
        // await this.getProjectsData();
        // await this.getAvailableProjectMembers();
        // await this.getMembers();
      }
      loading.close();
    },
  },
};
</script>

<style scoped lang="scss">
:root {
  --vs-search-input-placeholder-color: #bdbdbd;
  --vs-font-size: 14px;
}

.dropdown-toggle:not(.dropdown-dark):not(.dropdown-white) {
  width: 140px;
  border-radius: 0px 0px 0px 25px;
}

.page-header {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 20px;
  width: fit-content;

  .page-header-text {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .chevron {
    margin-left: 10px;
    color: #7b7b7b;
  }
}

.span-switch {
  display: flex;
  height: fit-content;
  gap: 15px;
}
.buttons {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}
.edit-role-btn {
  display: flex;
  align-items: center;
  width: max-content;
  border-radius: 0px 0px 0px 25px;
  padding: 8px 20px;
}
.dropdown-menu {
  padding: 0;
}
.form-button {
  border-radius: 0px 0px 0px 25px;
  border: 1px solid #7b7b7b;
  margin-right: 10px;
}
.modal-save {
  width: 140px;
  border-radius: 0px 0px 0px 25px;
  border: 1px solid #999797;
}

.filter {
  cursor: pointer;
  // border-bottom: 2px solid #ccc;
  font-size: 16px;

  padding: 5px 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.filter.active {
  width: max-content;
  color: #0071ce;
  border-bottom: 2px solid #0071ce;
  font-weight: 700;
}
.options-list {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  top: 143px;
  right: 8px;
  z-index: 100;
}
.template-header {
  display: flex;
  align-items: center;
}

.template-header i {
  cursor: pointer;
  font-size: 18px;
  margin-left: 10px;
  margin-bottom: 2px;
}

.member-select {
  flex: 1;
}
.fa-plus {
  font-size: 12px;
}
.fa-rotate {
  transform: rotate(180deg);
}
.template-save {
  width: 80px;
  border-radius: 0px 0px 0px 25px;
}
.add-btn {
  border-radius: 0px 0px 0px 25px;
}

@media (max-width: 542px) {
  .buttons {
    margin-right: 0;
  }
  .form-button {
    margin-top: 5px;
  }
  .span-switch {
    margin-left: 0;
    //flex-direction: column;
  }
  .options-list {
    top: 189px;
  }
  .dropdown-toggle:not(.dropdown-dark):not(.dropdown-white) {
    margin-top: 8px;
    //padding: 6px 13px!important;
  }
}
.organizationPage {
}
@media (max-width: 970px) {
  .organizationPage {
    // margin-top: 50px;
    max-height: calc(100vh - 500px);
    overflow: auto;
  }
}
@media (max-width: 650px) {
  .organizationPage {
    margin-top: 0;
  }
}
@media (max-width: 393px) {
  .buttons {
    align-items: flex-end;
  }
  .organization_container {
    // overflow-y: visible !important;
  }
}
.spinner_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  margin-left: 10px;
  margin-bottom: 24px;
}

.selectManager::v-deep {
  .vue-treeselect__input {
    color: #768192;
  }
}
.orgProjectDropdown {
  width: max-content;
  position: relative;
  bottom: 20px;
  left: 30px;
}

.organization_container {
  // height: calc(100vh - 220px);
  overflow-y: auto;
}
@media (max-width: 1133px) {
  .orgProjectDropdown {
    width: max-content;
    position: relative;
    top: 0;
    left: 20px;
  }
}
@media (max-width: 683px) {
  .orgProjectDropdown {
    margin-top: 36px;
  }
}

@media (max-width: 650px) {
  .orgheader {
    display: flex;
    flex-direction: column;
    gap: 20px;
    //margin-bottom: 100px;
  }
}

.filtersContainerRow {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
::v-deep .el-select__wrapper {
  height: 40px;
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #c3c3c3;
}

::v-deep .el-input__wrapper {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #c3c3c3;
}
</style>
