<template>
  <div>
    <CModal
      :show.sync="show"
      alignment="center"
      size="lg"
      :backdrop="true"
      :draggable="false"
      class="no-overflow"
    >
      <template v-slot:header>
        <h3>{{ $t("Select Role") }}</h3>
        <CButton @click="close">
          <i class="fa fa-times"></i>
        </CButton>
      </template>

      <div class="modal-body">
        <LevelData
          :levels="currentlevels"
          :levelOptions="levelOptions"
          :projectLevelOptions="projectLevelOptions"
          :projectData="projectData"
        />
      </div>
      <template v-slot:footer>
        <CButton @click="close" class="link">{{ $t("Cancel") }}</CButton>
        <CButton
          color="primary"
          class="modal-confirm"
          style="min-width: 85px; background: #0071ce"
          @click="addRole"
          data-testid="addNewRoleButton"
        >
          {{ $t("Save") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>
<script>
import LevelData from "../../components/organizationPage/LevelData.vue";
import vSelect from "vue-select";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import "vue-select/dist/vue-select.css";
import { useGlobalStore } from "@/stores/store";
import { UsersService } from "../../../../services/users";
import { OrganizationsService } from "@/services/organizations";

export default {
  data() {
    return {
      selectedMember: null,
      currentlevels: [],
      initialroles: [],
      globalStore: useGlobalStore(),
    };
  },
  components: {
    vSelect,
    Treeselect,
    LevelData,
  },
  computed: {
    processedOrgMembers() {
      return this.orgMembers.map(
        (member) => `${member.firstname} ${member.lastname}`
      );
    },
  },
  emits: ["close"],
  props: {
    show: Boolean,
    levelOptions: Array,
    projectLevelOptions: Array,
    levelsProvided: Boolean,
    orgMembers: Array,
    projectData: Object,
  },
  watch: {
    show: function (val) {
      if (val) {
        if (this.levelsProvided) {
          console.log(this.levelsProvided);
          this.levels = this.roles;
          this.currentlevels = this.globalStore.selectedUserRoles;
          console.log(this.currentlevels);
          this.initialroles = this.roles;
        }
      }
    },
    //     levels(val){
    // this.currentlevels=val;
    // console.log(this.currentlevels)
    //     }
  },
  updated() {},
  methods: {
    setCurrentLevels(val) {
      this.currentlevels = val;
    },
    close() {
      this.currentlevels = [];
      console.log(this.levels);
      console.log(this.initialroles);
      // this.$parent.levels=this.initialroles;
      this.$emit("close");
    },
    async addRole() {
      this.$parent.addRole(this.levels);
      // let selectedUser = this.globalStore.selectedUser;
      // selectedUser.roles = this.currentlevels;
      // const { data, res } = await OrganizationsService.edit_member({
      //   organization:
      //     this.currentOrganization.name ||
      //     this.currentOrganization.organization,
      //   project: this.currentProject.name,
      //   email: this.currentUserToEdit.email,
      //   user_data: this.currentUserToEdit,
      // });

      // if (data.success) {
      //   const userData = await UsersService.getActiveUserInfo();
      //   this.$store.commit("setJobsPoints", userData.data.level_points);
      //   showMessage("success", "User Edited successfully");
      //   this.$emit("close");
      //   this.$parent.refreshData();
      // }
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
:root {
  --vs-search-input-placeholder-color: #bdbdbd;
  --vs-font-size: 14px;
  --cui-modal-zindex: 9999999999999999999999;
  --cui-modal-bg: black;
}
</style>
<style>
.no-overflow .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal {
  background: rgb(10, 31, 143, 10%);
}
.modal-dialog {
  height: fit-content !important;
}
/* .no-overflow .modal .modal-dialog{
min-height: fit-content!important;
height:unset!important ;
} */
.no-overflow .modal-body {
  padding: 0;
  overflow: auto !important;
}
</style>
