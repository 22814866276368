<template>
  <div class="users_container">
    <el-table
      class="desktop--table org--table"
      :data="paginatedItems"
      :empty-text="$t('No data')"
      :border="parentBorder"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40" />
      <el-table-column type="expand" class="expand-column">
        <template #default="props">
          <div m="4">
            <el-table :data="props.row.roles" :border="childBorder">
              <el-table-column label="" />
              <el-table-column :label="$t('Levels')" prop="level_name" />
              <el-table-column
                :label="$t('Job Description')"
                prop="job_description"
              />
              <el-table-column :label="$t('Expertise')">
                <template #default="scope">
                  <span>{{
                    scope.row.expertise ? scope.row.expertise : "Not set yet"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('Director')">
                <template #default="scope">
                  {{ scope.row.admin ? $t("Yes") : $t("No") }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('Team leader')">
                <template #default="scope">
                  {{ scope.row.responsible ? $t("Yes") : $t("No") }}
                </template>
              </el-table-column>
              <el-table-column label="" />

              <!-- <el-table-column label="Zip" prop="zip" /> -->
            </el-table>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('First Name')"
        data-th="Movie Title"
        prop="firstname"
        sortable
        :sort-method="(a, b) => customSort(a, b, 'firstname')"
      />
      <el-table-column
        :label="$t('Last Name')"
        data-th="Movie Title"
        prop="lastname"
        sortable
        :sort-method="(a, b) => customSort(a, b, 'lastname')"
      />
      <el-table-column
        label="Email"
        prop="email"
        sortable
        :sort-method="(a, b) => customSort(a, b, 'email')"
      />
      <el-table-column
        :label="$t('Localization')"
        data-th="Movie Title"
        prop="localisation"
        sortable
        :sort-method="(a, b) => customSort(a, b, 'localisation')"
      />
      <el-table-column :label="$t('Phone')" prop="phone" />
      <el-table-column width="180">
        <template #header> </template>
        <template #default="scope">
          <div style="white-space: nowrap">
            <el-button
              v-if="doesHaveRight('modifyUsers')"
              size="small"
              link=""
              type="primary"
              :class="'action_btn ' + scope.row.id"
              @click="toggleEditUserModal(scope.row)"
              >{{ $t("Edit") }}</el-button
            >

            <el-popconfirm
              v-if="doesHaveRight('deleteUsers')"
              :title="
                $t('Are you sure to delete') +
                ' ' +
                scope.row.firstname +
                ' ' +
                scope.row.lastname
              "
              @confirm="toggleDeleteUserModal(scope.row.email)"
            >
              <template #reference>
                <el-button
                  link
                  size="small"
                  type="danger"
                  :class="'action_btn ' + scope.row.id"
                  >{{ $t("Delete") }}</el-button
                >
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-collapse style="min-height: 100px" class="small--screen--table">
      <el-collapse-item
        v-for="(item, index) in paginatedItems"
        :key="index"
        :name="index"
      >
        <template #title>
          <div
            class="d-flex align-items-center justify-content-between flex-wrap w-90 px-5 align-self-end"
            style="gap: 10px 32px"
          >
            <div class="w-100 d-flex gap-5">
              <div class="d-flex flex-column w-50 align-items-start w-50">
                <span for="" class="info--keys">{{ $t("First Name") }}</span>
                <span for="" class="info--values">{{ item.firstname }}</span>
              </div>
              <div class="d-flex flex-column w-50 align-items-start w-50">
                <span for="" class="info--keys">{{ $t("Last Name") }}</span>
                <span for="" class="info--values">{{ item.lastname }}</span>
              </div>
            </div>

            <div class="d-flex flex-column w-100 align-items-start">
              <span for="" class="info--keys">{{ $t("Email") }}</span>
              <span for="" class="info--values">{{ item.email }}</span>
            </div>
            <div class="w-100 d-flex gap-5">
              <div
                class="d-flex align-items-start justify-content-start flex-column"
                style="width: 33.33%"
              >
                <span for="" class="info--keys">{{ $t("Localization") }}</span>
                <span for="" class="info--values">{{ item.localisation }}</span>
              </div>
              <div
                class="d-flex align-items-start justify-content-start flex-column"
                style="width: 33.33%"
              >
                <span for="" class="info--keys">{{ $t("Phone") }}</span>
                <span for="" class="info--values">{{ item.phone }}</span>
              </div>

              <div
                class="d-flex align-items-start justify-content-start flex-column"
                style="width: 33.33%"
              >
                <span for="" class="info--keys">{{ $t("Actions") }}</span>
                <span for=""
                  ><el-button
                    size="small"
                    link
                    type="primary"
                    class="action_btn"
                    @click="toggleEditUserModal(item)"
                    ><i class="fa fa-pen"></i
                  ></el-button>
                  <el-button
                    link
                    size="small"
                    type="danger"
                    class="action_btn"
                    @click="toggleDeleteUserModal(item.email)"
                    ><i class="fa fa-trash"></i></el-button
                ></span>
              </div>
            </div>
          </div>
        </template>
        <div
          class="px-4"
          style="border: 1px solid #c3c3c3 !important; margin: 5px 5px"
        >
          <el-table :data="item.roles">
            <el-table-column label="Levels" prop="level_name" />
            <el-table-column label="Job Description" prop="job_description" />
            <el-table-column label="Director">
              <template #default="scope">
                {{ scope.row.admin ? $t("Yes") : $t("No") }}
              </template>
            </el-table-column>
            <el-table-column label="Team leader" prop="responsible" />
          </el-table>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div class="pagination-org">
      <div>
        <p style="font-size: 13px">
          {{ `${endIndex} / ${totalItems} ${$t("lines")}` }}
        </p>
      </div>

      <div>
        <MzPagination
          :align="center"
          :value="currentPage"
          @input="currentPage = $event"
          :active-page="currentPage"
          :pages="
            Math.ceil((orgMembers ? orgMembers.length : 0) / (perPage || 1))
          "
          @update:activePage="handlePageChange"
        >
          <template v-slot:previous-button>
            <i
              class="mz-icon icon-arrow-collapse pointer"
              @click="previousPage"
            ></i>
          </template>

          <template v-slot:next-button>
            <i
              class="mz-icon icon-arrow-collapse pointer"
              @click="nextPage"
            ></i>
          </template>
        </MzPagination>
      </div>
      <div class="d-flex">
        <span class="mt-2 mr-2" style="font-size: 13px"
          >{{ $t("Per page") }}:</span
        >

        <CSelect
          style="
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            cursor: pointer;
          "
          :value="perPage === this.orgMembers.length ? 'All' : perPage"
          :options="perPageOptions"
          @input="changePerPage($event.target.value)"
        >
        </CSelect>
      </div>
    </div>
    <CModal :show="showDeleteUserModal">
      <template v-slot:header>
        <h3>{{ $t("Delete user") }}</h3>
        <CButton @click="toggleDeleteUserModal">
          <i class="fa fa-times"></i>
        </CButton>
      </template>
      <div class="modal-body">
        <div class="text">
          <span
            >{{ $t("Are you sure you want to delete user") }}
            <b>{{ userToDelete }}</b> {{ $t("from the organization") }}
            <b
              >{{
                currentOrganization.name || currentOrganization.organization
              }}
              / {{ currentProject.name }}</b
            >?<br />{{
              $t(
                "This user will be permanently deleted from this organization"
              )
            }}.<br /><b
              >{{
                $t("All actions linked to this user will be also removed")
              }}.</b
            ></span
          >
        </div>
      </div>

      <template v-slot:footer>
        <CButton @click="showDeleteUserModal = false" color="link">{{
          $t("Close")
        }}</CButton>
        <CButton
          color="primary"
          class="add-btn"
          @click="deleteUser"
          :disabled="isRemovingMember"
          >{{ $t("Delete user") }}</CButton
        >
      </template>
    </CModal>

    <EditMemberModal
      :show="showEditUserModal"
      :newMember="newMember"
      :userToEdit="userToEdit"
      :selectedOrganization="selectedOrganization"
      :addMemberDisabled="addMemberDisabled"
      :locationOptions="locationOptions"
      :directionOptions="directionOptions"
      :categoryOptions="categoryOptions"
      :statusOptions="statusOptions"
      :itEquipmentOptions="itEquipmentOptions"
      :poleOptions="poleOptions"
      :jobDescriptionOptions="jobDescriptionOptions"
      :levelOptions="levelOptions"
      :projectLevelOptions="projectLevelOptions"
      :projectData="projectData"
      :projectRolesOptions="projectRolesOptions"
      @close="showEditUserModal = false"
    />
  </div>
</template>

<script>
import EditMemberModal from "../organizationSelect/modals/EditMemberModal.vue";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import { OrganizationsService } from "@/services/organizations";
import { useGlobalStore } from "@/stores/store";
import { UsersService } from "@/services/users";
import { mapState } from "vuex";

import { showMessage } from "../../../utils/helpers";
export default {
  name: "OrganizationTable",
  components: {
    Treeselect,
    EditMemberModal,
  },
  data() {
    return {
      showDeleteUserModal: false,
      globalStore: useGlobalStore(),

      showEditUserModal: false,
      userToDelete: "",
      multipleSelection: [],
      userToEdit: {
        first_name: "",
        last_name: "",
        email: "",
        n1: null,
        category: "",
        status: "",
        it_equipment: "",
        roles: [],
      },
      expandedRows: new Set(),
      origin: "",
      isRemovingMember: false,
    };
  },
  props: {
    tableItems: Array,
    orgMembers: Array,
    newMember: Object,
    availableProjectMembers: Array,
    tableFields: Array,
    perPage: Number,
    currentPage: Number,
    displayedItems: Number,
    totalPages: Number,
    perPageOptions: Array,
    isLoading: Boolean,
    locationOptions: Array,
    directionOptions: Array,
    poleOptions: Array,
    addMemberDisabled: Boolean,
    categoryOptions: Array,
    statusOptions: Array,
    itEquipmentOptions: Array,
    jobDescriptionOptions: Array,
    levelOptions: Array,
    projectLevelOptions: Array,
    orgViewData: Array,
    projectData: Object,
    allOrgViewData: Array,
    projectRolesOptions: Array,
  },

  computed: {
    ...mapState({
      userData: (state) => state.userData,
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
    }),
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      let end = this.currentPage * this.perPage;
      return end > this.totalItems ? this.totalItems : end;
    },
    totalItems() {
      return this.orgMembers ? this.orgMembers.length : 0;
    },
    paginatedItems() {
      if (this.orgMembers) {
        const start = (this.currentPage - 1) * this.perPage;
        const end = this.currentPage * this.perPage;

        const slicedArray = this.orgMembers.slice(start, end);

        return slicedArray;
      }
    },
    filteredAvailableProjectMembers() {
      if (!this.showEditUserModal || !this.userToEdit.email) {
        return this.availableProjectMembers;
      }
      return this.availableProjectMembers.filter(
        (member) => member.email !== this.userToEdit.email
      );
    },
  },

  methods: {
    customSort(a, b, prop) {
      const valueA = a[prop]?.toString().toLowerCase() || "";
      const valueB = b[prop]?.toString().toLowerCase() || "";
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    },
    doesHaveRight(right) {
      return this.$parent.doesHaveRight(right);
    },
    getLevelPath(levelName, orgViewData) {
      function flattenData(data, parentId = "") {
        return data.reduce((acc, level) => {
          const { children, members, ...levelWithoutChildren } = level;
          acc.push({ ...levelWithoutChildren, parent_id: parentId });
          if (children && children.length > 0) {
            acc = acc.concat(flattenData(children, level.id));
          }
          return acc;
        }, []);
      }
      let flatData = flattenData(orgViewData);
      let currentLevel = flatData.find(
        (level) => level.level_name === levelName
      );
      let path = currentLevel ? currentLevel.level_name : "";
      while (currentLevel && currentLevel.parent_id) {
        currentLevel = flatData.find(
          (level) => level.id === currentLevel.parent_id
        );
        if (currentLevel) {
          path = currentLevel.level_name + " / " + path;
        }
      }
      return path;
    },
    isRowExpanded(rowIndex) {
      return this.expandedRows.has(rowIndex);
    },
    toggleRow(rowIndex) {
      if (this.expandedRows.has(rowIndex)) {
        this.expandedRows.delete(rowIndex);
      } else {
        this.expandedRows.add(rowIndex);
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async deleteUser() {
      // this.isRemovingMember = true;

      let users = [];
      if (this.multipleSelection.length) {
        users = this.multipleSelection.map((user) => user.email);
      } else {
        users = [this.userToDelete];
      }
      if (users.includes(this.userData.email)) {
        showMessage("error", "You can't delete your own user!");
        this.showDeleteUserModal = false;
      } else {
        const { data, res } = await OrganizationsService.delete_member({
          users,
        });

        if (!data.success) {
          showMessage("error", "Error deleting user from organization!");
        } else {
          const userData = await UsersService.getActiveUserInfo();
          this.$store.commit("setJobsPoints", userData.data.level_points_v2);
          showMessage("success", "User deleted successfully");
          this.userToDelete = "";
          this.$parent.refreshEverything();
          this.showDeleteUserModal = false;
        }
      }

      this.isRemovingMember = false;
    },

    async editUser() {
      this.newMember.job_description = this.newMember.roles.job_description;
      this.newMember.firstname = this.newMember.first_name;
      this.newMember.lastname = this.newMember.last_name;
      this.newMember.roles.forEach((role) => {
        role["organization"] =
          this.currentOrganization.name ||
          this.currentOrganization.organization;
        role["project"] = this.currentProject.name;
      });
      const { data, res } = await OrganizationsService.edit_member({
        email: this.newMember.email,
        user_data: this.newMember,
      });
      if (!data.success) {
        showMessage("error", "Error editing user");
      } else {
        const userData = await UsersService.getActiveUserInfo();
        this.$store.commit("setJobsPoints", userData.data.level_points);
        showMessage("success", "Successfully added user");
        this.userToEdit = null;
        this.old_edited_user_email = "";
        this.showEditUserModal = false;
        this.refreshData();
      }
    },
    async refreshData() {
      await this.$parent.getTableData();
      await this.$parent.getProjectsData();
      await this.$parent.getAvailableProjectMembers();
      await this.$parent.getMembers();
      await this.$parent.list_organizations_and_projects();
    },
    toggleDeleteUserModal(email) {
      this.showDeleteUserModal = !this.showDeleteUserModal;
      this.userToDelete = email;
    },
    toggleEditUserModal(user_data) {
      this.showEditUserModal = true;
      if (user_data.n1 == "") {
        user_data.n1 = null;
      }
      this.globalStore.setSelectedUser(user_data);
      this.globalStore.setSelectedUserRoles(user_data["roles"]);
      this.userToEdit = user_data;
      this.old_edited_user_email = user_data["email"];
    },
    handlePageChange(newPage) {
      this.$emit("handle-page-change", newPage);
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.$emit("previous-page");
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit("next-page");
      }
    },
    changePerPage(value) {
      this.$emit("change-per-page", value);
    },
  },
  watch: {
    multipleSelection(val) {},
  },
};
</script>
<style>
.org--table {
  height: calc(100vh - 400px) !important;
  border-top: 1px solid rgb(195, 195, 195);
  border-right: 1px solid rgb(195, 195, 195);
  border-left: 1px solid rgb(195, 195, 195);
  border-bottom: none;
}
.desktop--table.el-table tr,
table tr {
  /* height: 60px !important; */
  background-color: white;
}
.desktop--table
  .el-table--enable-row-transition
  .el-table__body
  td.el-table__cell,
.desktop--table table tbody td {
  background-color: white;
}
.desktop--table.el-table td {
  background-color: white;
}
.desktop--table td.el-table__cell {
  background-color: white;
}
.desktop--table tr:hover,
.desktop--table
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background: #e7f0fa;
}
.action_btn {
  display: none;
}
.desktop--table tr:hover .action_btn {
  display: unset;
}
.desktop--table .el-table__inner-wrapper th,
.desktop--table .el-table__inner-wrapper td {
  background: #fff;
}
.desktop--table.el-table th {
  border-bottom: 1px solid gray;
}
.desktop--table
  .el-table__cell.el-table__expanded-cell
  .el-table__header-wrapper
  th,
.desktop--table .el-table__cell.el-table__expanded-cell .el-table__row td {
  background-color: #fff;
}
.org--table .el-table__body-wrapper {
  max-height: calc(100dvh - 380px) !important;
  overflow: auto;
}
button.el-collapse-item__header {
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
}
.el-collapse-item__arrow {
  margin: 0;
  width: 10%;
  transition: transform var(--el-transition-duration);
  font-weight: 300;
}
.el-collapse label {
  padding: 0;
  margin: 0;
  height: 15px;
}
.small--screen--table span {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
}
@media screen and (max-width: 1280px) {
  .org--table .el-table__body-wrapper {
    max-height: calc(100dvh - 380px);
  }
}

@media screen and (max-width: 1000px) {
  .el-table .el-table__cell {
    padding: 8px 8px;
  }
  .el-table .el-table__cell div {
    padding: 0;
  }
  .action_btn {
    display: unset;
  }
  .el-table {
    flex-direction: row;
    display: flex;
  }
}
.small--screen--table {
  display: none;
}
@media (max-width: 970px) {
  .desktop--table {
    display: none;
  }
  .small--screen--table {
    display: block;
  }
  .org--table .el-table__body-wrapper {
    max-height: calc(100dvh - 380px);
  }
}
@media (max-width: 770px) {
  .desktop--table {
    display: none;
  }
  .small--screen--table {
    display: block;
  }
  .org--table .el-table__body-wrapper {
    max-height: calc(100dvh - 380px);
  }
}
@media (min-width: 480px) {
  .el-table th,
  .el-table td {
    padding: 1em !important;
  }
}
@media (min-width: 480px) {
  .el-table th,
  .el-table td {
    display: table-cell;
    padding: 0.25em 0.5em;
  }
  .el-table th:first-child,
  .el-table td:first-child {
    padding-left: 0;
  }
  .el-table th:last-child,
  .el-table td:last-child {
    padding-right: 0;
  }
}
</style>
<style scoped lang="scss">
::v-deep .modal.show .modal-dialog {
  left: unset !important;
}
.tableStyles {
  max-height: calc(100vh - 300px);
  margin-top: 30px;
}
.info--keys {
  font-weight: 500;
  color: gray;
}
.info--values {
  font-size: 17px;
  font-weight: 500;
}

table {
  border: 1px solid rgb(202, 201, 201);
  padding: 35px;
}
.table-body {
  overflow-y: scroll;
  height: 200px !important;
  background-color: white;
}
.el-table tr th {
  vertical-align: middle;
  background-color: white;
}
.selectedTr {
  background-color: #cbd4df;
}
.table th,
.table td {
  border-top: none;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
}

.table td:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: unset;
}

.table td {
  word-wrap: break-word;
}
.orgTable {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  border-collapse: collapse;

  .spinner_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

// tr:hover {
//   background-color: #f1f1f1;
// }

.pagination-org {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  background-color: #fff;
  height: 58px;
  width: 100%;
  padding: 40px 20px 25px 20px;
  border-right: 1px solid rgb(195, 195, 195);
  border-bottom: 1px solid rgb(195, 195, 195);
  border-left: 1px solid rgb(195, 195, 195);
  border-top: 1px solid rgb(195, 195, 195);
}

@media screen and (max-width: 600px) {
  .table,
  .pagination-org {
    width: 100%;
    margin-left: 0;
  }
  .table th:nth-child(1),
  .table td:nth-child(1) {
    width: 10px;
  }
  th,
  td {
    //display: block;
    //text-align: right;
    //position: relative;
    min-width: 100%;
  }
  //
  //td::before {
  //  content: attr(data-label);
  //  position: absolute;
  //  left: 0;
  //  width: 50%;
  //  padding-right: 10px;
  //  text-align: left;
  //  font-weight: bold;
  //}
}

.actionButtons {
  display: flex;
  flex-direction: row;
  .editUser:hover {
    i {
      color: #6381da;
    }
  }

  .deleteUser:hover {
    i {
      color: rgb(218, 113, 99);
    }
  }
}

header.modal-header {
  border-bottom: none;
  display: none;
}

.text {
  margin-top: 20px;
}

.header {
  font-size: 18px;
  font-weight: 600;
}
::v-deep .el-scrollbar__wrap {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
</style>
