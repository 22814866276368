<template>
  <div>
    <CModal
      :show="show"
      :top="true"
      :draggable="false"
      class="editMemberModal"
      alignment="center"
    >
      <template v-slot:header>
        <h3 style="margin-left: 1rem">Edit Member</h3>

        <CButton @click="close">
          <i class="fa fa-times"></i>
        </CButton>
      </template>

      <div class="d-flex gap-3 flex-column" style="padding: 0 1rem">
        <div class="d-flex gap-2">
          <div style="flex: 1">
            <label for="FirstName">{{ $t("FirstName") }}</label>
            <el-input
              v-model="currentUserToEdit.firstname"
              :placeholder="$t('FirstName')"
            />
          </div>
          <div style="flex: 1">
            <label for="LastName">{{ $t("LastName") }}</label>
            <el-input
              v-model="currentUserToEdit.lastname"
              :placeholder="$t('LastName')"
            />
          </div>
        </div>
        <div>
          <label for="Email">{{ $t("Email") }}</label>
          <el-input
            :disabled="true"
            v-model="currentUserToEdit.email"
            :placeholder="$t('Email')"
          />
        </div>

        <div class="d-flex gap-2">
          <div style="flex: 1">
            <label for="Localisation">{{ $t("Localisation") }}</label>
            <el-input
              v-model="currentUserToEdit.localisation"
              :placeholder="$t('Localisation')"
            />
          </div>
          <div style="flex: 1">
            <label for="Phone">{{ $t("Phone Number") }}</label>
            <el-input
              v-model="currentUserToEdit.phone"
              :placeholder="$t('Phone Number')"
            />
          </div>
          <!-- <CInput
            class="w-50"
            :label="$t('Localisation')"
            :disabled="isAddingMember"
            v-model="currentUserToEdit.localisation"
          />

          <CInput
            class="w-50"
            :label="$t('Phone Number')"
            :disabled="isAddingMember"
            v-model="currentUserToEdit.phone"
          /> -->
        </div>
        <div style="margin-top: 10px">
          <LevelData
            :levels="currentUserToEdit.roles"
            :levelOptions="levelOptions"
            :projectLevelOptions="projectLevelOptions"
            :projectData="projectData"
            :show="show"
            :projectRolesOptions="projectRolesOptions"
            @update="update"
            @setRoles="setRoles"
          />
        </div>
      </div>

      <template v-slot:footer>
        <div class="d-flex justify-content-between w-100 align-items-center">
          <div style="left: 0; font-size: 12px">
            <i>* {{ $t("Mandatory fields") }}</i>
          </div>
          <div>
            <CButton @click="close" color="link">{{ $t("Cancel") }}</CButton>

            <CButton
              :disabled="editMemberDisabled"
              color="primary"
              style="border-radius: 0px 0px 0px 25px"
              class="modal-confirm"
              :disable="addMemberDisabled"
              @click="getReadyToEdit"
              >{{ $t("Edit member") }}</CButton
            >
            <!-- editMemberDisabled -->
          </div>
        </div>
      </template>
    </CModal>
    <AddRoleModal
      :show="showAddRoleModal"
      :roles="currentUserToEdit.roles"
      :levelsProvided="true"
      :newMember="newMember"
      :levelOptions="levelOptions"
      :projectLevelOptions="projectLevelOptions"
      :locationOptions="locationOptions"
      :origin="origin"
      :directionOptions="directionOptions"
      :poleOptions="poleOptions"
      :roleToEdit="roleToEdit"
      :jobDescriptionOptions="jobDescriptionOptions"
      :projectData="projectData"
      @close="closeaddRoleModal"
    />
  </div>
  <CModal
    :show="showConfirmRoleRemoval"
    :top="true"
    :draggable="false"
    alignment="center"
  >
    <template v-slot:header>
      <h3>Warning</h3>
      <CButton @click="showConfirmRoleRemoval = false">
        <i class="fa fa-times"></i>
      </CButton>
    </template>

    <div class="modal-body">
      <p style="font-size: 14px">
        A role was removed, this will remove all actions, news, events and
        challenges related to that role, are you sure you want to continue?
      </p>
    </div>
    <template v-slot:footer>
      <div class="d-flex justify-content-end">
        <div>
          <CButton @click="showConfirmRoleRemoval = false" color="link">{{
            $t("Close")
          }}</CButton>
          <CButton
            color="primary"
            style="border-radius: 0px 0px 0px 25px"
            class="add-btn"
            @click="
              confirmedRoleRemoval = true;
              showConfirmRoleRemoval = false;
            "
            >{{ $t("confirm") }}</CButton
          >
        </div>
      </div>
    </template>
  </CModal>
</template>

<script>
import { OrganizationsService } from "@/services/organizations";
import { UsersService } from "@/services/users";
import AddRoleModal from "./AddRoleModal.vue";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapState } from "vuex";
import { showMessage, updateMyData } from "@/utils/helpers";
import { useGlobalStore } from "@/stores/store";
import { useUserStore } from "@/stores/UserStore";
import LevelData from "../../components/organizationPage/LevelData.vue";

export default {
  data() {
    return {
      userStore: useUserStore(),
      levels: [],
      showAddRoleModal: false,
      currentUserToEdit: {
        first_name: "",
        last_name: "",
        email: "",
        n1: null,
        localisation: "",
        phone: "",
        roles: [],
      },
      globalStore: useGlobalStore(),
      showConfirmRoleRemoval: false,
      confirmedRoleRemoval: false,
    };
  },
  components: {
    AddRoleModal,
    Treeselect,
    vSelect,
    LevelData,
  },
  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
    }),
    editMemberDisabled() {
      return (
        !this.currentUserToEdit.firstname ||
        !this.currentUserToEdit.lastname ||
        !this.currentUserToEdit.email ||
        !this.currentUserToEdit.localisation ||
        !this.currentUserToEdit.phone ||
        !this.currentUserToEdit.roles.length ||
        this.currentUserToEdit.roles.some(
          (role) =>
            !role.job_description.length ||
            (role.roleType === "organization" && !role.job_description.length)
        )
      );
    },
  },
  props: {
    show: Boolean,
    newMember: Object,
    userToEdit: Object,
    locationOptions: Array,
    directionOptions: Array,
    categoryOptions: Array,
    poleOptions: Array,
    jobDescriptionOptions: Array,
    levelOptions: Array,
    projectLevelOptions: Array,
    statusOptions: Array,
    itEquipmentOptions: Array,
    projectData: Object,
    projectRolesOptions: Array,
  },
  mounted() {},
  methods: {
    setRoles(roles) {
      roles.forEach((v) => {
        if (v.roleType === "Organization") {
          if (v.admin) {
            v.frontOfficeRole = "Director";
          } else if (v.responsible) {
            v.frontOfficeRole = "Team Leader";
          } else {
            v.frontOfficeRole = "Collaborator";
          }
        }
      });
      this.currentUserToEdit.roles = roles;
    },
    update(arr) {
      this.currentUserToEdit.roles = Object.assign([], arr);
    },
    close() {
      this.confirmedRoleRemoval = false;
      this.$emit("close");
    },
    closeaddRoleModal() {
      this.showAddRoleModal = false;
      this.currentUserToEdit = this.globalStore.selectedUser;
    },
    getReadyToEdit() {
      this.currentUserToEdit.project_name = this.currentProject.name;
      this.editMember();
    },
    rolesEqualExcludingJobDescription(role1, role2) {
      return (
        role1.level === role2.level &&
        role1.org_id === role2.org_id &&
        role1.project === role2.project &&
        role1.roleType === role2.roleType &&
        role1.level_name === role2.level_name
      );
    },
    async editMember() {
      const originalRoles = this.userToEdit.roles;
      const currentRoles = this.currentUserToEdit.roles;

      if (!this.confirmedRoleRemoval) {
        const isRoleRemoved = originalRoles.some(
          (originalRole) =>
            !currentRoles.some((currentRole) =>
              this.rolesEqualExcludingJobDescription(originalRole, currentRole)
            )
        );

        if (isRoleRemoved) {
          this.showConfirmRoleRemoval = true;
          return;
        }
      }
      const { data, res } = await OrganizationsService.edit_member({
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization,
        project: this.currentProject.name,
        email: this.currentUserToEdit.email,
        user_data: this.currentUserToEdit,
      });
      if (data.success) {
        await this.updateCurrentJob();
        showMessage("success", "User Edited successfully");
        this.close();
        this.$parent.refreshData();
      }
    },
    async updateCurrentJob() {
      const findRoleDifferences = (originalRoles, editedRoles) => {
        const differences = [];

        originalRoles.forEach((originalRole, index) => {
          const editedRole = editedRoles[index];
          if (JSON.stringify(originalRole) !== JSON.stringify(editedRole)) {
            differences.push({ originalRole, editedRole });
          }
        });

        return differences;
      };

      const originalRoles = this.userToEdit.roles;
      const editedRoles = this.currentUserToEdit.roles;

      const changedRoles = findRoleDifferences(originalRoles, editedRoles);

      let shouldExit = false;

      for (const { originalRole, editedRole } of changedRoles) {
        if (this.userData.current_job_description[editedRole.job_description]) {
          const requestData = {
            job_description: editedRole.job_description,
            level: editedRole.level_name,
            role: editedRole.role,
          };
          const { data } = await OrganizationsService.change_job_desc(
            requestData
          );
          if (data.success) {
            updateMyData();
            shouldExit = true;
            break; // Exit the loop
          }
        }
      }

      if (!shouldExit) {
        updateMyData(); // Call updateMyData only if no changes were successful
      }
    },
    addRole(roles) {
      // this.currentUserToEdit.roles = roles;
    },
    editRole(role) {
      const index = this.currentUserToEdit.roles.findIndex(
        (r) => r === this.roleToEdit
      );
      if (index !== -1) {
        this.currentUserToEdit.roles[index] = role;
      } else {
        console.log("RoleToEdit not found in the roles array");
      }
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.currentUserToEdit = JSON.parse(
          JSON.stringify(this.globalStore.selectedUser)
        );
      }
    },
  },
};
</script>

<style>
.editMemberModal .modal-body {
  /* overflow:unset!important */
}
</style>
<style scoped>
.roleCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  color: #58595b;
  padding: 5px 0 10px 0;
  border-radius: 20px;
  border: 1px solid #58595b;
  width: 200px;
  margin: 0 12.5px 20px 0;
  box-sizing: border-box;
  cursor: pointer;
  background: #f2f8fd;
}
.roleCard:hover {
  background: #eceeef;
}
.modal-body {
  padding: 0 1rem !important;
}
::v-deep .modal-header {
  padding: 1rem 1rem 0 1rem !important;
}
.modal-confirm {
  border-radius: 0px 0px 0px 25px;
  padding: 10px;
  width: 105px;
  margin-left: 10px;
}

::v-deep .el-select__wrapper {
  border-radius: 0;
}

::v-deep .el-input__wrapper {
  border-radius: 0;
}
::v-deep .el-textarea__inner {
  border-radius: 0;
}
::v-deep .modal-footer {
  padding: 0.75rem 27px !important;
}
::v-deep .modal-dialog {
  top: unset !important;
  left: unset !important;
}
</style>
