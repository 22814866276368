<template>
  <div class="spinner--container" v-if="isLoading">
    <CSpinner style="border-right-color: white" />
  </div>
  <div class="orgViewData">
    <div>
      <OrganizationViewTree
        v-for="element in orgViewData"
        :key="element.id"
        :node="element"
        :depth="0"
        :fontSize="'22'"
        :expandedLevels="expandedLevels"
        :orgViewData="orgViewData"
        :orgMembers="orgMembers"
        :allLevels="allLevels"
        :allLevelsInOrgAndProject="allLevelsInOrgAndProject"
      />
    </div>
    <div>
      <CButton
        class="addLevelsButton"
        @click="openAddLevelModal(false)"
        v-if="doesHaveRight('addOrganizationLevels')"
        >{{ $t("Add Level") }}</CButton
      >
    </div>
    <div style="margin-top: 50px">
      <div
        style="
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          margin-bottom: 10px;
        "
        @click="toggleLevel()"
      >
        <i class="fa fa-chevron-down" style="cursor: pointer"></i>
        <h2>{{ $t("Project Team") }}</h2>
      </div>
      <div v-if="isExpanded">
        <OrganizationViewTree
          v-for="element in projectData.tree"
          :key="element.id"
          :node="element"
          :depth="0"
          :fontSize="'22'"
          :expandedLevels="expandedLevels"
          :orgMembers="orgMembers"
          :orgViewData="orgViewData"
          :allLevels="allLevels"
          :projectLevelOptions="projectLevelOptions"
          :projectData="projectData"
          :projectLevel="true"
          :allLevelsInOrgAndProject="allLevelsInOrgAndProject"
        />
      </div>
      <div v-if="doesHaveRight('addProjectLevels')">
        <CButton class="addLevelsButton" @click="openAddLevelModal(true)">{{
          $t("Add Level")
        }}</CButton>
      </div>
    </div>
    <AddRoleModal
      :key="new Date().getTime()"
      :show="showAddRoleModal"
      @close="showAddRoleModal = false"
      :currentLocation="currentLocation"
      :currentDirection="currentDirection"
      :newMember="newMember"
      :origin="'manager'"
      :locationOptions="locationOptions"
      :directionOptions="directionOptions"
      :poleOptions="poleOptions"
      :orgMembers="orgMembers"
    />

    <AddLevelsModal
      :show="showAddLevelModal"
      :currentParentId="currentParentId"
      :addRootProjectLevel="addRootProjectLevel"
      :allLevels="allLevels"
      @close="showAddLevelModal = false"
    />
  </div>
</template>
<script>
import vSelect from "vue-select";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import "vue-select/dist/vue-select.css";
import AddRoleModal from "../organizationSelect/modals/AddRoleModal.vue";
import AddLevelsModal from "../organizationSelect/modals/AddLevelsModal.vue";
import { ContentLoader } from "vue-content-loader";
import { ElLoading } from "element-plus";

import OrganizationViewTree from "./OrganizationViewTree.vue";
import { mapState } from "vuex";
export default {
  components: {
    AddRoleModal,
    AddLevelsModal,
    vSelect,
    Treeselect,
    OrganizationViewTree,
    ContentLoader,
  },
  data() {
    return {
      addRootProjectLevel: false,
      responsibleMembers: {
        level_0: {
          name: "",
          responsibleMember: {},
        },
        level_1: {
          name: "",
          responsibleMember: {},
        },
      },
      showAddRoleModal: false,
      selectedForModal: "",
      userToEdit: null,
      currentResponsible: null,
      savingNewResponsible: false,
      showAddLevelModal: false,
      levelType: "",
      currentParentId: "",
      currentLocation: "",
      currentDirection: "",
      // currentProject: null,
      showAddLevelForProjectModal: false,
      showSetManagerModal: false,
      currentMember: null,
      level: "",
      addMemberIn: {
        level_0: "",
        level_1: "",
        level_2: "",
      },
      additionalInfo: {
        level_0: "",
        level_1: "",
        level_2: "",
      },
    };
  },
  async created() {
    this.expandedLevels.add(this.currentProject.name);
  },
  props: {
    orgViewData: Array,
    orgMembers: Array,
    newMember: Object,
    projects: Array,
    projectData: Array,
    locationOptions: Array,
    directionOptions: Array,
    poleOptions: Array,
    isLoading: Boolean,
    expandedLevels: Set,
    allLevels: Set,
    expandAll: Boolean,
    projectLevelOptions: Array,
    allLevelsInOrgAndProject: Array,
  },
  watch: {
    expandAll() {
      console.log("changed");
      this.handleCollapseExpandAll();
    },
    isLoading(val) {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      if (val) {
        loading.visible = true;
      } else {
        loading.close();
      }
    },
  },
  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
    }),
    isExpanded() {
      return this.expandedLevels.has(this.currentProject.name);
    },
  },

  methods: {
    doesHaveRight(right) {
      return this.$parent.doesHaveRight(right);
    },
    setIsLoadingTrue() {
      this.$parent.setLoadingTrue();
    },
    toggleLevel() {
      this.expandedLevels.has(this.currentProject.name)
        ? this.expandedLevels.delete(this.currentProject.name)
        : this.expandedLevels.add(this.currentProject.name);
    },
    addNonEmptyChildrenToExpandedLevels(data) {
      for (let item of data) {
        if (item.children && item.children.length > 0) {
          this.expandedLevels.add(item.level_name);
          this.addNonEmptyChildrenToExpandedLevels(item.children);
        }
      }
    },
    handleCollapseExpandAll() {
      console.log("im here");
      this.setExpandedLevels(this.orgViewData, this.expandAll);
    },
    setExpandedLevels(data, expand) {
      for (let item of data) {
        if (expand) {
          this.expandedLevels.add(item.level_name);
        } else {
          this.expandedLevels.delete(item.level_name);
        }
        if (item.children && item.children.length > 0) {
          this.setExpandedLevels(item.children, expand);
        }
      }
    },
    getTooltipContent(level, direction) {
      const member = this.orgMembers.find((member) => {
        const foundRole = member.roles.find((role) => {
          if (direction) {
            return (
              role.level_0 &&
              role.level_0.name === level &&
              role.level_1 &&
              role.level_1.name === direction &&
              role.level_1.responsible === true
            );
          } else {
            return (
              role.level_0 &&
              role.level_0.name === level &&
              role.level_0.responsible === true
            );
          }
        });
        return foundRole !== undefined;
      });

      if (member) {
        const foundRole = member.roles.find((role) => {
          if (direction) {
            return (
              role.level_0 &&
              role.level_0.name === level &&
              role.level_1 &&
              role.level_1.name === direction
            );
          } else {
            return role.level_0 && role.level_0.name === level;
          }
        });

        const fullName = `${member.firstname} ${member.lastname}`;
        const jobDescription = foundRole
          ? foundRole.job_description
          : $t("No job description available");

        return `${fullName} - ${jobDescription}`;
      } else {
        return "Team leader";
      }
    },

    isResponsibleSelected(level, direction) {
      let currentResponsible = "";
      if (direction) {
        currentResponsible = this.orgMembers.find((member) => {
          return (
            member.roles.some((role) => role.level_1.responsible === true) &&
            member.roles.some((role) => role.level_1.name === direction) &&
            member.roles.some((role) => role.level_0.name === level)
          );
        });
      } else {
        currentResponsible = this.orgMembers.find((member) => {
          return (
            member.roles.some((role) => role.level_0.responsible === true) &&
            member.roles.some((role) => role.level_0.name === level)
          );
        });
      }
      // if (direction) {
      //   this.responsibleMembers.level_1 = {};
      //   this.responsibleMembers.level_1.name = `${level} - ${direction}`;
      //   this.responsibleMembers.level_1.responsibleMember = currentResponsible;
      // } else {
      //   this.responsibleMembers.level_0 = {};
      //   this.responsibleMembers.level_0.name = level;
      //   this.responsibleMembers.level_0.responsibleMember = currentResponsible;
      // }

      return !!currentResponsible;
    },

    openSetManagerModal() {
      this.showSetManagerModal = true;
    },

    async refreshData() {
      this.$parent.refreshEverything();
    },
    getFilteredMembers(locationName, directionName, pole) {
      const membersWithRoles = this.orgMembers.filter((member) => {
        return member.roles.some((role) => {
          return (
            role.level_0.name === locationName &&
            role.level_1.name === directionName &&
            role.level_2.name === pole
          );
        });
      });

      const result = membersWithRoles.flatMap((member) => {
        return member.roles.map((role) => {
          const memberCopy = { ...member };
          memberCopy.roles = [role];
          return memberCopy;
        });
      });

      const returnedResult = result.filter((member) => {
        return member.roles.some((role) => {
          return (
            role.level_0.name === locationName &&
            role.level_1.name === directionName &&
            role.level_2.name === pole
          );
        });
      });
      return returnedResult;
    },

    openAddRoleModal(location, direction) {
      if (location) {
        this.currentLocation = location;
      }
      if (direction) {
        this.currentDirection = direction;
      }
      this.showAddRoleModal = true;
    },
    addMemberFromTable(memberRoles, selectedMember) {
      this.$parent.addMemberFromTable(memberRoles, selectedMember);
    },
    addMember(location, direction, pole) {
      this.$parent.openUniqueMember(location, direction, pole);
    },

    async editMember() {
      this.newMember.roles.forEach((role) => {
        role["organization"] =
          this.currentOrganization.name ||
          this.currentOrganization.organization;
        role["project"] = this.currentProject.name;
      });

      const restructuredUserToEdit = {
        category: this.userToEdit.category,
        email: this.userToEdit.email,
        firstname: this.userToEdit.firstname,
        lastname: this.userToEdit.lastname,
        is_active: this.userToEdit.is_active,
        it_equipment: this.userToEdit.it_equipment,
        n1: "",
        status: this.userToEdit.status,
        roles: [
          {
            level_0: this.userToEdit.level_0,
            level_1: this.userToEdit.level_1,
            level_2: this.userToEdit.level_2,
            job_description: this.userToEdit.job_description,
            organization:
              this.currentOrganization.name ||
              this.currentOrganization.organization,
            project: this.currentProject.name,
          },
        ],
      };
      // const { data, res } = await OrganizationsService.edit_member({
      //   email: this.userToEdit ? this.userToEdit.email : this.newMember.email,
      //   user_data: restructuredUserToEdit || this.newMember,
      // });
    },

    openAddLevelModal(isProjectLevel) {
      this.addRootProjectLevel = isProjectLevel;
      this.showAddLevelModal = true;
    },
    openAddLevelForProjectModal(type, location, direction) {
      this.levelType = type;
      if (location) {
        this.selectedLocation = location;
      }
      if (direction) {
        this.selectedDirection = direction;
      }
      this.showAddLevelForProjectModal = true;
    },
  },
};
</script>
<style scoped>
.addLevelsButton {
  color: #0071ce;
  background: #deebf7;
  font-weight: bold;
}
.spaceBottom {
  margin-bottom: 30px;
}
.responsibleSelected {
  color: #0071ce;
}
.orgViewData {
  margin-top: 30px;
  padding: 0 30px;
  overflow: scroll;
  height: calc(100dvh - 230px);
}
</style>
