<template>
  <div
    class="position-relative"
    style="
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      position: relative;
      /* gap:10px */
    "
  >
    <div class="role--component" v-if="currentlevels.length">
      <div @click="prevLevel" style="cursor: pointer">
        <svg-icon type="mdi" :path="rightChev"></svg-icon>
        <svg viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z"
              fill="#000000"
            ></path>
          </g>
        </svg>
      </div>
      <div
        v-for="(currentLevel, index) in currentlevels"
        :key="index"
        :class="[
          'w-100',
          this.currentIndex == index ? 'd-flex' : 'd-none',
          'level',
        ]"
        v-show="currentIndex == index"
      >
        <div class="bg-white w-100">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <div
              class="w-100"
              style="display: flex; flex-direction: column; gap: 16px"
            >
              <div class="roleTypeInput">
                <label :for="'roleType' + index">{{ $t("Type") }}:</label>
                <el-select
                  :data-testid="'roleType-' + index"
                  class="style-chooser"
                  :id="'roleType' + index"
                  v-model="currentLevel.roleType"
                  label="text"
                  @change="
                    () => {
                      $emit('update', currentlevels);
                      resetFields(index);
                    }
                  "
                  track-by="value"
                  :placeholder="$t('Role Type')"
                >
                  <el-option
                    v-for="item in roleTypeOptions.map((el) => ({
                      value: el,
                      label: el,
                      id: el,
                    }))"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                /></el-select>
              </div>

              <div
                v-if="currentLevel.roleType"
                class="d-flex flex-wrap gap-2 flex-column"
              >
                <div class="levelName">
                  <div style="width: 50%" class="Organization-roleType">
                    <label :for="'levelName' + index"
                      >{{ $t("Level Name") }}:</label
                    >

                    <el-select
                      :data-testid="'levelName-' + index"
                      class="style-chooser Organization-roleType"
                      :id="'levelName' + index"
                      v-model="currentLevel.level_name"
                      @change="() => $emit('update', currentlevels)"
                      @input="$emit('update', currentlevels)"
                      label="text"
                      track-by="value"
                      :placeholder="$t('Select A Level')"
                    >
                      <el-option
                        v-for="item in currentLevel.roleType === 'Organization'
                          ? levelOptions.map((el) => ({
                              value: el,
                              label: el,
                              id: el,
                            }))
                          : projectLevelOptions.map((el) => ({
                              value: el,
                              label: el,
                              id: el,
                            }))"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                  <div
                    v-if="currentLevel.roleType === 'Organization'"
                    style="width: 50%"
                    class="Organization-roleType"
                  >
                    <label :for="'jobDescription' + index"
                      >{{ $t("Job Description") }}:</label
                    >
                    <!-- <CInput
                      data-testid="jobDescriptionInput"
                      class="jobInput"
                      type="text"
                      :id="'jobDescription' + index"
                      v-model="currentLevel.job_description"
                      @input="() => $emit('update', currentlevels)"
                      :placeholder="$t('Enter Job Description')"
                    /> -->
                    <el-input
                      data-testid="jobDescriptionInput"
                      v-model="currentLevel.job_description"
                      @input="() => $emit('update', currentlevels)"
                      :placeholder="$t('Enter Job Description')"
                    />
                    <div></div>
                  </div>

                  <div
                    v-if="currentLevel.roleType === 'Project'"
                    style="width: 50%"
                    class="Organization-roleType"
                  >
                    <label :for="'jobDescription' + index"
                      >{{ $t("Job Description") }}:</label
                    >
                    <div>
                      <el-select
                        class="style-chooser Organization-roleType"
                        style="border: 0"
                        :id="'jobDescription' + index"
                        v-model="currentLevel.job_description"
                        label="text"
                        track-by="value"
                        @change="
                          () => {
                            $emit('update', currentlevels);
                          }
                        "
                        @input="() => $emit('update', currentlevels)"
                        :placeholder="$t('Job Description')"
                      >
                        <el-option
                          v-for="item in BackOfficeRole.map((el) => ({
                            value: el.name,
                            label: el.name,
                            id: el.name,
                          }))"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="Organization-roleType">
                  <label :for="'expertise' + index"
                    >{{ $t("Expertise") }}:</label
                  >

                  <el-input
                    data-testid="expertiseInput"
                    v-model="currentLevel.expertise"
                    @input="() => $emit('update', currentlevels)"
                    :placeholder="$t('Enter Expertise')"
                  />
                </div>
                <div v-if="currentLevel.roleType === 'Organization'">
                  <label
                    for="frontOfficeRole"
                    style="font-size: 15px; margin-top: 5px"
                  >
                    {{ $t("Role type") }}:
                  </label>
                  <div>
                    <el-select
                      class="style-chooser Organization-roleType"
                      style="border: 0"
                      id="frontOfficeRole"
                      v-model="currentLevel.frontOfficeRole"
                      label="text"
                      track-by="value"
                      :placeholder="$t('Job Description')"
                      @change="
                        () => {
                          $emit('update', currentlevels);
                        }
                      "
                    >
                      <el-option
                        v-for="item in frontOfficeOptions.map((el) => ({
                          value: el,
                          label: el,
                          id: el,
                        }))"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
            <button @click="deleteLevel(index)" class="trash--btn">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.50098 6C5.63358 6 5.76076 6.05268 5.85453 6.14645C5.9483 6.24021 6.00098 6.36739 6.00098 6.5V12.5C6.00098 12.6326 5.9483 12.7598 5.85453 12.8535C5.76076 12.9473 5.63358 13 5.50098 13C5.36837 13 5.24119 12.9473 5.14742 12.8535C5.05366 12.7598 5.00098 12.6326 5.00098 12.5V6.5C5.00098 6.36739 5.05366 6.24021 5.14742 6.14645C5.24119 6.05268 5.36837 6 5.50098 6ZM8.00097 6C8.13358 6 8.26076 6.05268 8.35453 6.14645C8.4483 6.24021 8.50097 6.36739 8.50097 6.5V12.5C8.50097 12.6326 8.4483 12.7598 8.35453 12.8535C8.26076 12.9473 8.13358 13 8.00097 13C7.86837 13 7.74119 12.9473 7.64742 12.8535C7.55365 12.7598 7.50097 12.6326 7.50097 12.5V6.5C7.50097 6.36739 7.55365 6.24021 7.64742 6.14645C7.74119 6.05268 7.86837 6 8.00097 6ZM11.001 6.5C11.001 6.36739 10.9483 6.24021 10.8545 6.14645C10.7608 6.05268 10.6336 6 10.501 6C10.3684 6 10.2412 6.05268 10.1474 6.14645C10.0537 6.24021 10.001 6.36739 10.001 6.5V12.5C10.001 12.6326 10.0537 12.7598 10.1474 12.8535C10.2412 12.9473 10.3684 13 10.501 13C10.6336 13 10.7608 12.9473 10.8545 12.8535C10.9483 12.7598 11.001 12.6326 11.001 12.5V6.5Z"
                  fill="#7B7B7B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.501 3.5C14.501 3.76521 14.3956 4.01957 14.2081 4.2071C14.0205 4.39464 13.7662 4.5 13.501 4.5H13.001V13.5C13.001 14.0304 12.7903 14.5391 12.4152 14.9142C12.0401 15.2893 11.5314 15.5 11.001 15.5H5.00097C4.47054 15.5 3.96183 15.2893 3.58676 14.9142C3.21169 14.5391 3.00098 14.0304 3.00098 13.5V4.5H2.50098C2.23576 4.5 1.98141 4.39464 1.79387 4.2071C1.60633 4.01957 1.50098 3.76521 1.50098 3.5V2.5C1.50098 2.23478 1.60633 1.98043 1.79387 1.79289C1.98141 1.60536 2.23576 1.5 2.50098 1.5H6.00097C6.00097 1.23478 6.10633 0.980429 6.29387 0.792893C6.4814 0.605357 6.73576 0.5 7.00097 0.5H9.00097C9.26619 0.5 9.52054 0.605357 9.70808 0.792893C9.89561 0.980429 10.001 1.23478 10.001 1.5H13.501C13.7662 1.5 14.0205 1.60536 14.2081 1.79289C14.3956 1.98043 14.501 2.23478 14.501 2.5V3.5ZM4.11897 4.5L4.00097 4.559V13.5C4.00097 13.7652 4.10633 14.0196 4.29387 14.2071C4.4814 14.3946 4.73576 14.5 5.00097 14.5H11.001C11.2662 14.5 11.5205 14.3946 11.7081 14.2071C11.8956 14.0196 12.001 13.7652 12.001 13.5V4.559L11.883 4.5H4.11897ZM2.50098 3.5V2.5H13.501V3.5H2.50098Z"
                  fill="#7B7B7B"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div @click="nextLevel" style="cursor: pointer">
        <svg-icon type="mdi" :path="leftChev"></svg-icon>
        <svg viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.29289 4.29289C8.68342 3.90237 9.31658 3.90237 9.70711 4.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L9.70711 19.7071C9.31658 20.0976 8.68342 20.0976 8.29289 19.7071C7.90237 19.3166 7.90237 18.6834 8.29289 18.2929L14.5858 12L8.29289 5.70711C7.90237 5.31658 7.90237 4.68342 8.29289 4.29289Z"
              fill="#000000"
            ></path>
          </g>
        </svg>
      </div>
    </div>
    <div>
      <div
        data-testid="addRoleLevelData"
        :disabled="noInput"
        color="primary"
        style="
          background: transparent;
          border: 0;
          color: #0071ce;
          display: flex;
          align-items: center;
          gap: 5px;
          justify-content: center;
          font-weight: 600;
          margin: 15px 15px 15px 0;
          cursor: pointer;
        "
        @click="addLevel"
      >
        {{ $t("Add role") }}
        <svg
          width="17"
          height="17"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0002 19.8002C11.8202 19.8002 11.675 19.7454 11.5646 19.6358C11.455 19.5254 11.4002 19.3802 11.4002 19.2002V12.6002H4.8002C4.6202 12.6002 4.4754 12.5402 4.3658 12.4202C4.2554 12.3002 4.2002 12.1602 4.2002 12.0002C4.2002 11.8202 4.2554 11.675 4.3658 11.5646C4.4754 11.455 4.6202 11.4002 4.8002 11.4002H11.4002V4.8002C11.4002 4.6202 11.4602 4.475 11.5802 4.3646C11.7002 4.255 11.8402 4.2002 12.0002 4.2002C12.1802 4.2002 12.3254 4.255 12.4358 4.3646C12.5454 4.475 12.6002 4.6202 12.6002 4.8002V11.4002H19.2002C19.3802 11.4002 19.5254 11.4602 19.6358 11.5802C19.7454 11.7002 19.8002 11.8402 19.8002 12.0002C19.8002 12.1802 19.7454 12.3254 19.6358 12.4358C19.5254 12.5454 19.3802 12.6002 19.2002 12.6002H12.6002V19.2002C12.6002 19.3802 12.5402 19.5254 12.4202 19.6358C12.3002 19.7454 12.1602 19.8002 12.0002 19.8002Z"
            fill="#0071CE"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronRight, mdiChevronLeft } from "@mdi/js";
import svgIconVue from "@jamescoyle/vue-icon";

export default {
  name: "LevelData",
  components: { vSelect, SvgIcon },
  data() {
    return {
      frontOfficeOptions: ["Collaborator", "Team Leader", "Director"],
      level: {
        level_name: "",
        job_description: "",
        rightChev: mdiChevronRight,
        rightChev: mdiChevronLeft,
        admin: false,
        responsible: false,
        roleType: "",
        frontOfficeRole: "Collaborator",
      },
      currentlevels: [],
      currentIndex: 0,
      roleTypeOptions: ["Organization", "Project"],
    };
  },
  emits: ["setlevels", "update", "setRoles"],
  props: {
    levels: Array,
    levelOptions: Array,
    projectLevelOptions: Array,
    projectData: Object,
    show: Boolean,
    projectRolesOptions: Array,
  },
  computed: {
    noInput() {
      if (this.levels.length > 0) {
        const lastLevel = this.levels[this.levels.length - 1];
        if (lastLevel.roleType === "") {
          return true;
        }
        switch (lastLevel.roleType) {
          case "Organization":
            return (
              lastLevel.level_name === "" || lastLevel.job_description === ""
            );
          case "Project":
            return (
              lastLevel.level_name === "" ||
              lastLevel.job_description === null ||
              lastLevel.job_description === undefined ||
              lastLevel.job_description.length === 0
            );
          default:
            return true;
        }
      }
      return false;
    },
    BackOfficeRole() {
      return this.projectRolesOptions;
    },
  },
  mounted() {},
  methods: {
    addLevel() {
      this.currentlevels.push({
        level_name: "",
        job_description: "",
        expertise: "",
        admin: false,
        responsible: false,
        roleType: "",
        frontOfficeRole: "Collaborator",
      });
      this.$emit("setCurrentLevels", this.currentlevels);
      this.currentIndex = this.currentlevels.length - 1;
    },
    deleteLevel(index) {
      this.currentlevels.splice(index, 1);
      if (index > 0 && index === this.currentIndex) {
        this.currentIndex--;
      }
    },
    nextLevel() {
      if (this.currentIndex < this.currentlevels.length - 1) {
        this.currentIndex++;
        this.currentLevel = { ...this.currentlevels[this.currentIndex] };
      }
    },
    prevLevel() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.currentLevel = { ...this.currentlevels[this.currentIndex] };
      }
    },
    resetCurrentLevel() {
      this.currentLevel = {
        level_name: "",
        job_description: "",
        expertise: "",
        admin: false,
        responsible: false,
        roleType: "",
      };
    },
    resetFields(index) {
      this.currentlevels[index] = {
        level_name: "",
        job_description: "",
        expertise: "",
        admin: false,
        responsible: false,
        roleType: this.currentlevels[index].roleType,
        frontOfficeRole: "Collaborator",
      };
      this.$emit("update", this.currentlevels);
      // console.log("this.currentlevels[index]", this.currentlevels[index]);
    },
  },
  watch: {
    levels(val) {
      val.forEach((v) => {
        if (v.roleType === "Organization") {
          if (v.frontOfficeRole) {
            switch (v.frontOfficeRole) {
              case "Collaborator":
                v.admin = false;
                v.responsible = false;
                break;
              case "Director":
                v.admin = true;
                v.responsible = false;
                break;
              case "Team Leader":
                v.admin = false;
                v.responsible = true;
                break;
              default:
                break;
            }
          }
        }
      });
      this.currentlevels = val;
      this.$emit("setRoles", val);
    },
    show(val) {
      if (!val) {
        this.currentlevels = [];
      }
    },
  },
};
</script>

<style lang="scss">
:root {
  --vs-search-input-placeholder-color: #bdbdbd;
  --vs-font-size: 14px;
  --vs-dropdown-min-width: max-content;
}
.v-select .vs__dropdown-menu {
  color: #333333;
}

.large-checkbox {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.jobInput::v-deep {
  input {
    height: 32px;
    border-color: #cdcdcd;
  }
}
.jobInput::v-deep input::placeholder {
  color: red;
  opacity: 1; /* Adjust if needed */
}
.img {
  width: 18px;
  height: 18px;
}

.trash--btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
  color: white;
  border: 0;
  background: white;
  border-bottom: 1px solid #c3c3c3;
  border-left: 1px solid #c3c3c3;
}
.trash--btn:hover {
  background: rgb(235, 234, 234);
}
.prevBtn {
  position: absolute;
  cursor: pointer;
  top: 34%;
  left: 0;
}
.nextClass {
  position: absolute;
  cursor: pointer;
  top: 34%;
  right: 0;
}
.level {
  position: relative;
  border: 1px solid #c3c3c3;
  background: white;
  padding: 15px 20px;
  display: flex;
}
.role--component {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 10px;
  width: 100%;
  padding: 20px;
}
@media (max-width: 788px) {
  .role--component {
    gap: 0;
  }
  .level {
    padding: 0;
    border: 0;
    align-items: center;
    padding: 20px 0;
    width: 80% !important;
  }
  .trash--btn {
    color: red;

    border: 1px solid #c3c3c3;
  }
  .modal-footer {
    justify-content: flex-start;
  }
}
@media (max-width: 488px) {
  .roleTypeInput {
    width: 100%;
  }
  div .style-chooser,
  .Organization-roleType {
    width: 100% !important;
  }

  .levelName {
    flex-direction: column;
    align-items: flex-start !important;
    width: 100%;
  }
}
// .vs__dropdown-menu {
//   position: fixed !important;
//   top: 520px;
//   left: 720px;
//   width: 300px;
//   z-index: 9999;
// }
.levelName {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 100%;
}
</style>

<style scoped>
::v-deep .el-select__wrapper {
  height: 20px !important;
  border-radius: 0;
}

::v-deep .el-input__wrapper {
  border-radius: 0;
}
::v-deep .el-textarea__inner {
  border-radius: 0;
}
</style>
