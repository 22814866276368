<template>
  <div>
    <CModal
      :show="showBuildProjectTeamModal"
      @hide="closeBuildProjectTeamModal"
      :closeOnBackdrop="true"
    >
      <template v-slot:footer>
        <CButton @click="closeBuildProjectTeamModal" class="link"
          >Cancel</CButton
        >
      </template>

      <div class="header">
        <h3>Manage project teams</h3>
        <CButton @click="closeBuildProjectTeamModal">
          <i class="fa fa-times"></i>
        </CButton>
      </div>
      <div class="options">
        <div
          v-for="project in projects"
          class="option"
          @click="openProjectModal(project)"
        >
          <div>
            <i
              v-c-tooltip="'Build project team'"
              class="fa fa-chevron-right"
              @click="toggleExpand"
            ></i>
            {{ project.name }}
          </div>

          <div class="optionButtons" v-show="hoveredOption === option">
            <div v-c-tooltip="'Edit project name'">
              <i
                @click.stop="openEditModal(project)"
                class="blue fa fa-pencil"
              />
            </div>
            <div v-c-tooltip="'Delete project'">
              <img
                @click.stop="openDeleteModal(project)"
                class="img"
                :src="require('@/assets/Images/cross.png')"
                alt="delete"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="add" @click="openAddProjectModal">
        <div class="text">Add new project</div>
        <div class="sign">+</div>
      </div>

      <CModal
        :show="showProjectModal"
        @hide="closeProjectModal"
        :closeOnBackdrop="true"
      >
        <div class="preHeader">
          <div @click="closeProjectModal">
            <i class="fa fa-chevron-left"></i>
            <span>{{ selectedProject.name }}</span>
          </div>
          <CButton @click="closeProjectModal">
            <i class="fa fa-times"></i>
          </CButton>
        </div>
        <div class="header">
          <span>Build project team</span>
        </div>
        <template v-slot:footer>
          <CButton @click="closeProjectModal" class="link">Cancel</CButton>
          <CButton @click="openSaveAsTemplate" class="modal-save"
            >Save as template</CButton
          >
          <CButton @click="saveTeam" color="primary" class="modal-create"
            >Save team</CButton
          >
        </template>
        <div>
          <!-- Templates Section -->
          <div class="template-header">
            <h5>Templates</h5>
            <i
              class="fa fa-chevron-down"
              :class="{ 'fa-rotate': showTemplateSlider }"
              @click="toggleTemplateSlider"
            ></i>
          </div>
          <div class="template-slider" v-show="showTemplateSlider">
            <div class="template-boxes">
              <div
                v-for="(template, index) in templates"
                :key="index"
                class="template-box"
                @mouseenter="hoveredTemplateIndex = index"
                @mouseleave="hoveredTemplateIndex = null"
                :class="
                  selectedTemplate && selectedTemplate.name == template.name
                    ? 'selected'
                    : ''
                "
                @click="selectTemplate(template)"
              >
                <CButton
                  @click.stop="deleteTemplate(template)"
                  class="deleteTemplateCross"
                  v-show="hoveredTemplateIndex === index"
                  v-c-tooltip="'Delete template'"
                >
                  <i class="fa fa-times"></i>
                </CButton>
                <div>{{ template.name }}</div>
              </div>
            </div>
          </div>
          <div>
            <h5>Project Team</h5>
            <div
              v-for="(role, index) in selectedProject.team"
              :key="index"
              class="teamMember"
            >
              <CInput
                v-model="role.role_name"
                class="mb-2 roleInput"
                placeholder="Role"
              />
              <Treeselect
                v-model="role.members"
                :multiple="true"
                :options="availableProjectMembers"
                :limit="1"
                :openDirection="'above'"
              />
              <CButton
                @click="removeRole(index)"
                v-c-tooltip="'Remove role'"
                class="removeRoleButton"
              >
                <i class="fa fa-times"></i>
              </CButton>
            </div>
            <!-- <CButton @click="addRole" color="link"
              >Add Role <i class="fa fa-plus text-info"></i
            ></CButton> -->
            <div class="add" @click="addRole">
              <div class="sign">+</div>
              <div class="text">Add new role</div>
            </div>
          </div>
        </div>
      </CModal>
    </CModal>
    <CModal
      id="saveTemplateModal"
      @hide="closeSaveAsTemplate"
      :show.sync="showSaveAsTemplateModal"
      alignment="center"
      size="sm"
    >
      <div class="header">
        <h3>Save as template</h3>
        <CButton @click="closeSaveAsTemplate">
          <i class="fa fa-times"></i>
        </CButton>
      </div>
      <div class="inputHeader">Template name</div>
      <div class="inputWrapper">
        <input class="input" v-model="templateNameToSave" />
      </div>
      <template v-slot:footer>
        <CButton @click="closeSaveAsTemplate" class="ml-3" color="link">{{
          $t("Close")
        }}</CButton>
        <CButton color="primary" class="template-save" @click="saveTemplate">{{
          $t("Save")
        }}</CButton>
      </template>
    </CModal>
    <CModal
      id="editModal"
      @hide="closeEditModal"
      :show.sync="showEditModal"
      alignment="center"
    >
      <div class="header">Rename project {{ projectToRename }}</div>
      <div class="inputWrapper">
        <CInput placeholder="New name" class="input" v-model="newProjectName" />
      </div>
      <template v-slot:footer>
        <CButton @click="closeEditModal" class="link">Cancel</CButton>
        <CButton @click="renameProject" color="primary" class="modal-confirm">
          {{ $t("Save") }}
        </CButton>
      </template>
    </CModal>
    <CModal
      id="deleteModal"
      @hide="closeDeleteModal"
      :show.sync="showDeleteModal"
      alignment="center"
    >
      <div>
        <!-- <div class="header">Delete project</div> -->
        <div class="text">
          {{ $t("Are you sure you want to delete the project") }}
          <b
            ><span>{{ projectToDelete }}</span></b
          >?
        </div>
      </div>

      <template slot="header-wrapper">
        <div class="HiddenWrapper" />
      </template>
      <template v-slot:footer>
        <CButton @click="closeDeleteModal" class="link">Cancel</CButton>
        <CButton @click="deleteProject" color="primary" class="modal-confirm">
          Delete
        </CButton>
      </template>
    </CModal>
    <CModal
      id="addProjectModal"
      @hide="closeAddProjectModal"
      :show.sync="showAddProjectModal"
      alignment="center"
    >
      <div class="header">Add new project</div>
      <div class="inputHeader">Project name</div>
      <div class="inputWrapper">
        <input class="input" v-model="projectToCreate" />
      </div>
      <template v-slot:footer>
        <CButton @click="closeAddProjectModal" class="link">Cancel</CButton>
        <CButton
          @click="addProject(projectToCreate)"
          color="primary"
          class="modal-create"
        >
          Add
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import { ProjectsService } from "@/services/projects";
import { showMessage } from "../../../utils/helpers";

export default {
  name: "OgranizationBuildProjectTeamModal",
  data() {
    return {
      hoveredTemplateIndex: null,
      templates: [],
      templateNameToSave: "",
      projectToCreate: null,
      projectToRename: null,
      projectToDelete: null,
      showProjectModal: false,
      showAddProjectModal: false,
      showDeleteModal: false,
      showEditModal: false,
      showSaveAsTemplateModal: false,
      selectedProject: { name: "", team: [] },
      showTemplateSlider: true,
      projectTeam: [],
      members: [],
      newProjectName: "",
      selectedtemplate: null,
    };
  },
  components: { Treeselect },
  props: {
    showBuildProjectTeamModal: Boolean,
    availableProjectMembers: Array,
    projects: Array,
    organization: String,
  },
  mounted() {
    this.getProjectTeamTemplates();
    //console.log("this.availableProjectMembers", this.availableProjectMembers);
  },
  methods: {
    selectTemplate(templateObj) {
      this.selectedTemplate = this.templates.find(
        (template) => template.name === templateObj.name
      );
      // Clear the existing team array
      this.selectedProject.team = [];
      // Create a new team with role_names from the selected template
      this.selectedTemplate.structure.forEach((role_name) => {
        this.selectedProject.team.push({
          role_name: role_name,
          members: [],
        });
      });
    },

    openAddProjectModal() {
      this.showAddProjectModal = true;
    },

    closeAddProjectModal() {
      this.showAddProjectModal = false;
    },

    openProjectModal(project) {
      this.showProjectModal = true;
      this.selectedProject = project;
    },

    closeProjectModal() {
      this.showProjectModal = false;
      this.selectedTemplate = null;
      this.selectedProject = { name: "", team: [] };
    },

    openEditModal(project) {
      this.showEditModal = true;
      this.projectToRename = project.name;
    },

    closeEditModal() {
      this.showEditModal = false;
      this.projectToRename = null;
    },

    openDeleteModal(project) {
      this.showDeleteModal = true;
      this.projectToDelete = project.name;
    },

    closeDeleteModal() {
      this.showDeleteModal = false;
      this.projectToDelete = null;
    },

    closeBuildProjectTeamModal() {
      this.showProjectModal = false;
      this.selectedProject = { name: "", team: [] };
      this.selectedTemplate = null;
      this.$emit("close-build-project-team-modal");
    },

    openSaveAsTemplate() {
      this.showSaveAsTemplateModal = true;
    },

    closeSaveAsTemplate() {
      this.showSaveAsTemplateModal = false;
    },

    toggleTemplateSlider() {
      this.showTemplateSlider = !this.showTemplateSlider;
    },

    addProject(name) {
      this.projects.push({
        name: name,
        team: [{ role_name: "", members: [] }],
      });
      //console.log(this.projects);
      this.showAddProjectModal = false;
      this.openProjectModal({
        name: name,
        team: [{ role_name: "", members: [] }],
      });
    },

    addRole() {
      this.selectedProject.team.push({ role_name: "", members: [] });
    },

    async deleteTemplate(template) {
      const { data, res } = await ProjectsService.delete_template({
        name: template.name,
      });
      if (!res.ok) {
        showMessage("error", "Error deleting template!");
      } else {
        this.getProjectTeamTemplates();
      }
    },

    async deleteProject() {
      const { data, res } = await ProjectsService.delete_project({
        project_name: this.projectToDelete,
      });
      if (!res.ok) {
        showMessage("error", "Error deleting project team!");
      } else {
        this.projectToDelete = "";
        this.closeDeleteModal();
        this.$emit("getProjectsData");
      }
    },

    async renameProject() {
      const { data, res } = await ProjectsService.rename_project({
        project_name: this.projectToRename,
        new_project_name: this.newProjectName,
      });
      if (!res.ok) {
        showMessage("error", "Error saving project team!");
      } else {
        this.projectToRename = "";
        this.newProjectName = "";
        this.closeEditModal();
        this.$emit("getProjectsData");
      }
    },

    async getProjectTeamTemplates() {
      const { data, res } = await ProjectsService.list_templates();
      this.templates = data.data;
    },

    async saveTeam() {
      const { data, res } = await ProjectsService.save_project({
        organization: this.organization,
        name: this.selectedProject.name,
        team: this.selectedProject.team,
      });
      if (!res.ok) {
        showMessage("error", "Error saving project team!");
      } else {
        this.closeProjectModal();
        this.$emit("getProjectsData");
      }
    },

    async removeRole(index) {
      this.selectedProject.team.splice(index, 1);
    },

    async saveTemplate() {
      const { data, res } = await ProjectsService.save_template({
        name: this.templateNameToSave,
        structure: this.selectedProject.team.map((role) => role.role_name),
      });
      this.showSaveAsTemplateModal = false;
      this.templateNameToSave = "";
      await this.getProjectTeamTemplates();
      const template = this.templates[this.templates.length - 1];
      this.selectTemplate(template);
    },
  },
};
</script>

<style scoped lang="scss">
.modal-create {
  width: fit-content;
  min-width: 100px;
  border-radius: 0px 0px 0px 25px;
}
.removeRoleButton {
  height: fit-content;
  margin-left: 5px;
}

.img {
  width: 12px;
  height: 12px;
  margin-left: 15px;
}
.add {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 50px;
  padding: 0px 0px 0px 0px;
  color: #858585;
  width: fit-content;
  transition: 0.1s;

  .text {
    height: 20px;
    line-height: 25px;
  }

  &:hover {
    color: #0071ce;
  }

  .sign {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: 100;
    font-size: 25px;
    margin-left: 5px;
    margin-top: 2px;
  }
}
.selected {
  background-color: #e7f0fa;
}
.options {
  border-bottom: 1px solid #e7f0fa;

  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 10px;
    cursor: pointer;

    i {
      margin-right: 5px;
    }

    .optionButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      div {
        display: flex;
      }

      .blue {
        color: #0071ce;
      }

      i {
        margin-left: 10px;
      }
    }

    &:hover {
      background-color: #e7f0fa;
    }
  }
}

.roleInput {
  flex: 1;
  margin-right: 10px;
}
.preHeader {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  span {
    margin-left: 10px;
  }
}
.header {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.teamMember {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
}

footer.modal-footer {
  padding-top: 0;
  border-top: none;
}

header.modal-header {
  display: none;
}

.vue-treeselect {
  width: 50%;
}

.vue-treeselect__menu-container {
  z-index: 9999 !important;
  bottom: 100%; /* Align the bottom of the menu with the bottom of the select box */
  top: auto; /* Override the default top value */
}
.template-box {
  border: 1px solid #ccc;
  padding: 7px;
  margin: 5px 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  .deleteTemplateCross {
    /* ... (styles for deleteTemplateCross) ... */
    display: none; /* Initially hide the deleteTemplateCross */
  }

  &:hover {
    .deleteTemplateCross {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
      display: block; /* Show deleteTemplateCross when template-box is hovered */
    }
  }

  div {
    text-align: center;
  }
}
.template-boxes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  .selected {
    background-color: #f7fafe;
    border-color: #0071ce;
  }
}

// .modal-body {
//     height: 50vh;
// }

#deleteModal .modal-dialog,
#editModal .modal-dialog {
  top: 30% !important;
}

#saveTemplateModal .modal-dialog {
  top: 20% !important;
}

.inputWrapper {
  border-bottom: 1px solid #9d9d9d;
}

input {
  border: none;
  width: 100%;
  padding: 5px 5px 5px 0px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
}
.header {
  font-size: 18px;
  font-weight: 600;
}

.inputHeader {
  margin-top: 10px;
  font-size: 12px;
}
</style>
