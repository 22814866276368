<template>
  <div>
    <CModal :show.sync="show" size="md" class="uniqueMember" :draggable="false">
      <template v-slot:header>
        <h3 style="padding-left: 1rem">{{ $t("Add member") }}</h3>
        <CButton @click="closeUniqueMember">
          <i class="fa fa-times"></i>
        </CButton>
      </template>

      <div
        class="d-flex flex-column gap-2"
        style="overflow: auto; padding: 0 1rem"
      >
        <div class="d-flex gap-2">
          <div>
            <label for="FirstName">{{ $t("FirstName") }}</label>
            <el-input
              :disabled="isAddingMember"
              v-model="newMember.first_name"
              data-testid="newMemberFirstName"
              :placeholder="$t('FirstName')"
            />
          </div>
          <div>
            <label for="LastName">{{ $t("LastName") }}</label>
            <el-input
              :disabled="isAddingMember"
              data-testid="newMemberLastName"
              v-model="newMember.last_name"
              :placeholder="$t('LastName')"
            />
          </div>
        </div>
        <div>
          <label for="email">{{ $t("Email") }}</label>
          <el-input
            :disabled="isAddingMember"
            data-testid="newMemberEmail"
            v-model="newMember.email"
            :placeholder="$t('Email')"
          />
        </div>
        <div class="d-flex gap-2">
          <div style="flex: 1">
            <label for="localisation">{{ $t("Localisation") }}</label>
            <el-input
              :disabled="isAddingMember"
              data-testid="newMemberLocalisation"
              v-model="newMember.localisation"
              :placeholder="$t('Localisation')"
            />
          </div>
          <div style="flex: 1">
            <label for="phone">{{ $t("Phone Number") }}</label>
            <el-input
              :disabled="isAddingMember"
              data-testid="newMemberPhone"
              v-model="newMember.phone"
              :placeholder="$t('Phone Number')"
            />
          </div>
        </div>
        <LevelData
          :levels="roles"
          :levelOptions="levelOptions"
          :projectLevelOptions="projectLevelOptions"
          :projectData="projectData"
          @update="update"
          :show="show"
          @setRoles="setRoles"
          :projectRolesOptions="projectRolesOptions"
        />
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-between w-100 align-items-center">
          <div style="left: 0; font-size: 12px">
            <i>* {{ $t("Mandatory fields") }}</i>
          </div>
          <div>
            <CButton @click="closeUniqueMember" color="link">{{
              $t("Cancel")
            }}</CButton>

            <CButton
              data-testid="createNewMemberButton"
              :disabled="addMemberDisabled || isAddingMember"
              color="primary"
              class="modal-confirm"
              style="border-radius: 0px 0px 0px 25px"
              @click="addMember"
              >{{ $t("Add member") }}
            </CButton>
          </div>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import AddRoleModal from "./AddRoleModal.vue";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import LevelData from "../../components/organizationPage/LevelData.vue";
export default {
  data() {
    return {
      showAddRoleModal: false,
      roles: [],
    };
  },
  components: {
    AddRoleModal,
    Treeselect,
    vSelect,
    LevelData,
  },
  props: {
    show: Boolean,
    newMember: Object,
    userToEdit: Object,
    addMemberDisabled: Boolean,
    levelOptions: Array,
    projectLevelOptions: Array,
    categoryOptions: Array,
    jobDescriptionOptions: Array,
    statusOptions: Array,
    itEquipmentOptions: Array,
    origin: String,
    isAddingMember: Boolean,
    projectData: Object,
    projectRolesOptions: Array,
  },
  updated() {
    // console.log(this.addMemberDisabled , this.isAddingMember)
  },
  methods: {
    setRoles(roles) {
      this.newMember.roles = roles;
    },
    closeUniqueMember() {
      this.roles = [];
      this.$emit("close");
    },
    addMember() {
      this.$parent.addMember(this.roles);
    },
    addRole(addedRoles) {
      this.roles = addedRoles;
    },
    update(arr) {
      this.roles = Object.assign([], arr);
    },
  },
  watch: {
    addMemberDisabled(val) {},
  },
};
</script>
<style>
/* .uniqueMember .modal-body{
overflow: unset
} */
</style>
<style scoped>
.roleCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  color: #58595b;
  padding: 5px 0 10px 0;
  border-radius: 20px;
  border: 1px solid #58595b;
  width: 200px;
  margin: 0 12.5px 20px 0;
  box-sizing: border-box;
  cursor: pointer;
  background: #f2f8fd;
}
.roleCard:hover {
  background: #eceeef;
}
.modal-body {
  padding: 0 1rem !important;
}
::v-deep .modal-header {
  padding: 1rem 1rem 0 1rem !important;
}
.modal-confirm {
  border-radius: 0px 0px 0px 25px;
  padding: 10px;
  width: 105px;
  margin-left: 10px;
}

::v-deep .el-select__wrapper {
  border-radius: 0;
}

::v-deep .el-input__wrapper {
  border-radius: 0;
}
::v-deep .el-textarea__inner {
  border-radius: 0;
}
::v-deep .modal-footer {
  padding: 0.75rem 27px !important;
}
::v-deep .modal-dialog {
  top: unset !important;
  left: unset !important;
}
</style>
