const permissionsText = {
  viewOrganizationPage: "View Organization Page",
  viewMembers: "View Members",
  addUniqueUser: "Add Unique User",
  addBulkUsers: "Add Bulk Users",
  deleteUsers: "Delete Users",
  modifyUsers: "Modify Users",
  viewLevels: "View Levels",
  addProjectLevels: "Add Project Levels",
  addOrganizationLevels: "Add Organization Levels",
  modifyProjectLevels: "Modify Project Levels",
  modifyOrganizationLevels: "Modify Organization Levels",
  deleteProjectLevels: "Delete Project Levels",
  deleteOrganizationLevels: "Delete Organization Levels",
  addUsersToLevels: "Add Users to Levels",

  viewActionsPage: "View Actions Page",
  viewOwnPlan: "Consult Your Action Plan",
  viewTeamPlan: "Consult Team Action Plan",
  viewAllPlan: "Consult All Action Plans",
  addOwnAction: "Add Personal Action",
  addTeamAction: "Add Team Action",
  addAllAction: "Add Action for All",
  modifyOwnProgress: "Update Personal Progress",
  modifyTeamProgress: "Update Team Progress",
  modifyAllProgress: "Update All Progress",
  modifyOwnAction: "Edit Personal Action",
  modifyTeamAction: "Edit Team Action",
  modifyAllAction: "Edit Any Action",
  removeOwnAction: "Remove Personal Action",
  removeTeamAction: "Remove Team Action",
  removeAllAction: "Remove Any Action",
  exportActions: "Export Actions Data",
  bulkUploadAction: "Bulk Upload Actions",
  orderActions: "Reorder Actions",

  viewAgendaPage: "View Agenda Page",
  viewEvents: "View Scheduled Events",
  createEvents: "Create New Events",
  modifyEvents: "Edit Scheduled Events",
  deleteEvents: "Delete Scheduled Events",

  viewCommunicationPage: "View Communications Page",

  viewTrainingPage: "View Training Page",
  manageModules: "Manage Modules",
  manageTrainings: "Manage Trainings",
  editAttandance: "Edit Attandance",
  subscribeMe: "Subscribe To Own Training",
  subscribeTeam: "Subscribe To Team Training",
  subscribeALL: "Subscribe To All Training",

  viewReportingPage: "View Reporting Page",

  viewChallengesPage: "View Challenges Page",
};

export default permissionsText;
